import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export default function FilterItem({ onChange, ...props }) {
  return (
    <Input.Search
      placeholder="Поиск по названию"
      allowClear
      onSearch={(v) => onChange("name", v)}
      enterButton="Найти"
      prefix={<SearchOutlined />}
      {...props}
    />
  );
}
