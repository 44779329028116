import { useEffect, useState } from "react";
import { Table, message, Tag, Modal, Dropdown } from "antd";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import { pathOr, includes } from "ramda";
import { useNavigate } from "react-router-dom";
import View from "../view";
import dayjs from "dayjs";
import { useAuth } from "@contexts";
import * as API from "@api";
import * as Filters from "./filters";

export default function DataTable({
  showHeader = true,
  showFilters = ["search", "email", "status", "dates"],
  defaultFilterValues = null,
  pageSize = 10,
  showPagination = true,
}) {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({
    id: pathOr(undefined, ["id"], defaultFilterValues),
    email: pathOr(undefined, ["email"], defaultFilterValues),
    blocked: pathOr(undefined, ["blocked"], defaultFilterValues),
    created_at: pathOr(undefined, ["created_at"], defaultFilterValues),
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
    showTotal: (total) => `Entries found: ${total}`,
  });

  useEffect(() => {
    getTableData();
  }, [filter, search]);

  async function getTableData(params = pagination, _, sorter) {
    setLoading(true);
    const _res = await API.Users.getAll({
      s: search,
      filter,
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
      order: sorter?.field && {
        [sorter?.field]: sorter?.order === "ascend" ? "ASC" : "DESC",
      },
    });

    if (!_res.data) {
      return message.error(
        "Ошибка при получении данных. Пожалуйста, попробуйте снова позже."
      );
    }

    setTableData(_res.data.rows);
    setPagination({
      ...params,
      total: _res.data.count,
      showTotal: (total) => `Найдено записей: ${total}`,
    });
    setLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter(
      { ...filter, [f]: v || undefined },
      setPagination({ ...pagination, current: 1 })
    );
  }

  function onSearch(v) {
    setSearch(v, setPagination({ ...pagination, current: 1 }));
  }

  const columns = [
    {
      dataIndex: "info",
      key: "info",
      align: "left",
      title: "Пользователь",
      render: (_, data) => (
        <div>
          <span className="block align-middle text-base font-medium text-black">
            {data?.display_name}
          </span>
          <span className="block text-sm font-normal text-neutral-400">
            {data?.email}
          </span>
        </div>
      ),
    },
    {
      dataIndex: "role",
      key: "role",
      align: "center",
      title: "Роль",
      render: (data) => {
        return (
          <div>
            {data === "admin" && (
              <Tag className="m-0" color="blue">
                Администратор
              </Tag>
            )}

            {data === "content-manager" && (
              <Tag className="m-0" color="orange">
                Контент-менеджер
              </Tag>
            )}

            {data === "reception" && (
              <Tag className="m-0" color="green">
                Ресепшн
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Статус",
      dataIndex: "blocked",
      key: "blocked",
      align: "center",
      render: (blocked) => (
        <Tag className="m-0" color={blocked ? "red" : "green"}>
          {blocked ? "Заблокирован" : "Активный"}
        </Tag>
      ),
      sorter: (a, b) => a?.blocked - b?.blocked,
    },
    {
      title: "Посл. вход",
      dataIndex: "last_login",
      key: "last_login",
      align: "center",
      render: (data) => (
        <span className="m-0 leading-tight">
          <i className="text-neutral-500 not-italic">
            {dayjs(data).format("HH:mm")}
          </i>{" "}
          &middot; {dayjs(data).format("DD MMM YYYY")}
        </span>
      ),
      sorter: (a, b) => dayjs(a.updated_at) - dayjs(b.updated_at),
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (_, all) => (
        <div className="flex justify-end items-center">
          <Dropdown.Button
            disabled={
              currentUser?.id !== all?.id
                ? currentUser?.role !== "admin"
                : false
            }
            className="w-fit"
            menu={{
              items: [
                {
                  label: "Быстрый просмотр",
                  key: "view",
                  onClick: () => setId(all.id),
                  icon: <EyeOutlined />,
                },
              ],
            }}
            onClick={() => navigate(`/users/edit/${all?.id}`)}
          >
            <EditOutlined /> Открыть
          </Dropdown.Button>
        </div>
      ),
    },
  ];

  return (
    <div className="grid grid-cols-12 gap-4">
      {includes("search", showFilters) && (
        <div className="col-span-12 lg:col-span-6">
          <Filters.Search onSearch={onSearch} />
        </div>
      )}
      {includes("dates", showFilters) && (
        <div className="col-span-12 lg:col-span-6">
          <Filters.Dates onChange={onFilterChange} />
        </div>
      )}
      <div className="col-span-12">
        <Table
          showHeader={showHeader}
          onChange={getTableData}
          pagination={showPagination && pagination}
          dataSource={tableData}
          loading={loading}
          className="rounded-sm overflow-hidden w-full"
          rowKey="id"
          scroll={{ x: "max-content" }}
          columns={columns}
        />
      </div>
      <Modal
        centered
        destroyOnClose
        width={950}
        open={!!id}
        onCancel={() => setId(null)}
        closable={false}
        okButtonProps={{ style: { display: "none" } }}
        cancelText="Закрыть"
      >
        <View id={id} />
      </Modal>
    </div>
  );
}
