import { Form, Select } from "antd";

export default function InputField(props) {
  return (
    <Form.Item
      {...props}
      name={["meta", "slider"]}
      className="w-full"
      label="Показывать слайдер с фото в шапке?"
      rules={[
        {
          required: true,
          message: "Пожалуйста, выберите опцию",
        },
      ]}
    >
      <Select
        placeholder="Выберите опцию"
        className="w-full"
        options={[
          {
            label: "Да",
            value: true,
          },
          {
            label: "Нет",
            value: false,
          },
        ]}
      />
    </Form.Item>
  );
}
