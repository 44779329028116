import { Form, InputNumber } from "antd";

export default function ShelterId(params) {
  return (
    <div>
      <Form.Item
        className="w-full"
        label="ID номера в Shelter"
        name={["meta", "shelter_id"]}
        help="Если вы хотите, чтобы название и подзаголовок в форме бронирования брались с сайта, а не с Shelter, укажите ID типа номера в Shelter."
      >
        <InputNumber controls={false} className="w-full" placeholder="ID" />
      </Form.Item>
      <span className="block text-base font-medium mt-2">
        Как узнать ID номера в Shelter?{" "}
        <a href="/img/shelter_id.png" target="_blank">
          Открыть
        </a>
      </span>
      <ol className="list-decimal list-outside pl-5">
        <li>Зайдите в Shelter и нажмите на свое имя в правом верхнем углу</li>
        <li>Нажмите вкладку «Онлайн для сайта v2.0»</li>
        <li>Нажмите вкладку «Показать все настройки»</li>
        <li>
          Во вкладке «Категории номеров» найдите нужный вам номер по названию
        </li>
        <li>
          В поле «Ссылка для виджета» в самой ссылке в конце есть текст (напр.
          ...roomTypeId=46944). 46944 и есть ID номера в Shelter.
        </li>
      </ol>
    </div>
  );
}
