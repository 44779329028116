import { Form, Input } from "antd";
import { LinkOutlined, PhoneOutlined } from "@ant-design/icons";

export default function InputField(props) {
  return (
    <div className="flex flex-col gap-4">
      <Form.Item
        {...props}
        name="contacts_facebook"
        className="w-full"
        label="Ссылка на Facebook"
      >
        <Input
          addonBefore={<LinkOutlined />}
          controls={false}
          className="w-full"
          placeholder="Вставьте ссылку на Facebook"
        />
      </Form.Item>
      <Form.Item
        {...props}
        name="contacts_instagram"
        className="w-full"
        label="Ссылка на Instagram"
      >
        <Input
          addonBefore={<LinkOutlined />}
          controls={false}
          className="w-full"
          placeholder="Вставьте ссылку на Instagram"
        />
      </Form.Item>
      <Form.Item
        {...props}
        name="contacts_x"
        className="w-full"
        label="Ссылка на X (ex Twitter)"
      >
        <Input
          addonBefore={<LinkOutlined />}
          controls={false}
          className="w-full"
          placeholder="Вставьте ссылку на X"
        />
      </Form.Item>
      <Form.Item
        {...props}
        name="contacts_whatsapp"
        className="w-full"
        label="Номер WhatsApp Business"
      >
        <Input
          addonBefore={<PhoneOutlined />}
          controls={false}
          className="w-full"
          placeholder="Вставьте номер WhatsApp Business"
        />
      </Form.Item>
    </div>
  );
}
