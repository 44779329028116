import { find } from "ramda";
import TextBlock from "./text";
import TextAreaBlock from "./textarea";
import ImageBlock from "./image";
import ImageCarouselBlock from "./carousel";
import HTMLBlock from "./html";
import Slider from "./slider";
import Raw from "./raw";
import People from "./people";
import Booking from "./booking";
import MiniOptions from "./minioptions";
import Photocards from "./photocards";
import Maps from "./maps";
import Fishmap from "./fishmap";
import Buttons from "./buttons";
import SwitchOption from "./options/switch";
import SelectOption from "./options/select";
import TextOption from "./options/text";
import TextAreaOption from "./options/textarea";
import { Divider } from "antd";

export default function Blocks({
  data = null,
  widgets = [],
  onChange,
  onOptionChange,
}) {
  if (!data || !widgets) return null;

  const widget = find((w) => w.type === data.type, widgets);
  const inputs = widget?.meta?.inputs || [];
  const options = widget?.meta?.options
    ? Object.keys(widget.meta.options).map((key) => {
        return { key, data: widget.meta.options[key] };
      })
    : [];

  return (
    <div className="grid grid-cols-12 gap-4 my-3">
      {inputs.map((input, key) => {
        switch (input.type) {
          case "text":
            return (
              <TextBlock
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          case "textarea":
            return (
              <TextAreaBlock
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          case "image":
            return (
              <ImageBlock
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          case "images":
            return (
              <ImageCarouselBlock
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          case "html":
            return (
              <HTMLBlock
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          case "raw":
            return (
              <Raw input={input} onChange={onChange} key={key} data={data} />
            );

          case "media":
            return (
              <Slider input={input} onChange={onChange} key={key} data={data} />
            );

          case "people":
            return (
              <People input={input} onChange={onChange} key={key} data={data} />
            );

          case "fishmap":
            return (
              <Fishmap
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          case "buttons":
            return (
              <Buttons
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          case "minioptions":
            return (
              <MiniOptions
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          case "booking":
            return (
              <Booking
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          case "maps":
            return (
              <Maps input={input} onChange={onChange} key={key} data={data} />
            );

          case "photocards":
            return (
              <Photocards
                input={input}
                onChange={onChange}
                key={key}
                data={data}
              />
            );

          default:
            break;
        }
      })}

      <Divider className="w-full col-span-12 my-2" />

      {options.map((o) => {
        switch (o.data.type) {
          case "select":
            return (
              <SelectOption option={o} onChange={onOptionChange} data={data} />
            );

          case "radio":
            return (
              <SwitchOption option={o} onChange={onOptionChange} data={data} />
            );

          case "text":
            return (
              <TextOption option={o} onChange={onOptionChange} data={data} />
            );

          case "textarea":
            return (
              <TextAreaOption
                option={o}
                onChange={onOptionChange}
                data={data}
              />
            );

          default:
            break;
        }
      })}
    </div>
  );
}
