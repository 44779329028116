export default function NotFound() {
  return (
    <div className="flex w-full h-full justify-center align-middle p-5">
      <div className="mt-16">
        <h1 className="text-2xl text-center">Не найдено</h1>
        <p className="text-center text-base max-w-2xl mt-8">
          Страница не найдена или была удалена. Вернуться на{" "}
          <a href="/">Главную</a>.
        </p>
      </div>
    </div>
  );
}
