import { Navigate, useSearchParams } from "react-router-dom";
import { useAuth } from "@contexts";
import { Login } from "@modules";
import { startsWith } from "ramda";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import style from "./style.module.scss";
import config from "@config";

export default function LoginPage(props) {
  const { currentUser } = useAuth();
  const [params] = useSearchParams();
  const redirectURL = params.get("redirect_url") || null;

  // If user is authenticated
  if (currentUser) {
    // Check for redirect URL
    if (redirectURL && !startsWith("/", redirectURL)) {
      return window.location.replace(redirectURL);
    }

    return <Navigate to={redirectURL || "/"} />;
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaKey}>
      <div className={style.container}>
        <img src="/img/logo.svg" className={style.logo} alt="Logo" />
        <Login.Form className="w-full max-w-sm my-10" />
      </div>
    </GoogleReCaptchaProvider>
  );
}
