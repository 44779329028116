import { Modal, Select, Input } from "antd";
import { useEffect, useState } from "react";
import { LinkOutlined } from "@ant-design/icons";
import * as API from "@api";

export default function PageSelector({ open, onCancel, onAdd }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [pageOptions, setPageOptions] = useState([]);

  useEffect(() => {
    if (!open) return;
    API.Pages.getAll({ filter: { is_main: 0 }, limit: 100 }).then((res) => {
      console.log("res", res);
      setPageOptions(res?.data?.rows || []);
      setLoading(false);
    });
  }, [open]);

  useEffect(() => {
    if (!open) return;
    setData({});
  }, [open]);

  // if type changed, clear data
  useEffect(() => {
    if (!open) return;
    setData({
      ...data,
      page_id: null,
      link: null,
      label: null,
      label_kk: null,
      label_en: null,
      label_cn: null,
    });
  }, [data?.type]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title="Добавить страницу в навигацию"
      centered
      width={900}
      okText="Добавить"
      okButtonProps={{
        disabled:
          !data?.type ||
          !data?.label ||
          !data?.label_kk ||
          !data?.label_cn ||
          !data?.label_en ||
          (data?.type === "page" && !data?.page_id) ||
          (data?.type === "link" && !data?.link),
      }}
      onOk={() => {
        onAdd(data);
      }}
    >
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Тип ссылки
          </span>
          <Select
            loading={loading}
            disabled={loading}
            className="w-full"
            placeholder="Выберите тип ссылки"
            allowClear={false}
            options={[
              { label: "Страница", value: "page" },
              { label: "Ссылка", value: "link" },
            ]}
            onChange={(value) => {
              setData({
                ...data,
                type: value,
              });
            }}
            value={data?.type}
          />
        </div>
        {data?.type === "page" && (
          <div className="col-span-12">
            <span className="block text-sm font-medium text-neutral-800 mb-2">
              Страница
            </span>
            <Select
              loading={loading}
              disabled={loading}
              className="w-full"
              placeholder="Выберите страницу"
              allowClear={false}
              options={
                pageOptions?.map((page) => ({
                  label: page.name,
                  value: page.id,
                })) || []
              }
              onChange={(value) => {
                setData({
                  ...data,
                  link:
                    "/" + pageOptions?.find((page) => page.id === value)?.slug,
                  page_id: value,
                  label: pageOptions?.find((page) => page.id === value)?.name,
                  label_kk: pageOptions?.find((page) => page.id === value)
                    ?.name_kk,
                  label_en: pageOptions?.find((page) => page.id === value)
                    ?.name_en,
                  label_cn: pageOptions?.find((page) => page.id === value)
                    ?.name_cn,
                });
              }}
              value={data?.page_id}
            />
          </div>
        )}
        {data?.type === "link" && (
          <div className="col-span-12">
            <span className="block text-sm font-medium text-neutral-800 mb-2">
              Ссылка
            </span>
            <Input
              value={data?.link}
              onChange={(e) => {
                setData({
                  ...data,
                  link: e.target.value,
                });
              }}
              addonBefore={<LinkOutlined />}
              placeholder="Введите ссылку"
            />
          </div>
        )}
        {data?.type && [
          <div key="label" className="col-span-12 md:col-span-3">
            <span className="block text-sm font-medium text-neutral-800 mb-2">
              Название (на-русском)
            </span>
            <Input
              value={data?.label}
              onChange={(e) => {
                setData({
                  ...data,
                  label: e.target.value,
                });
              }}
              placeholder="Введите название страницы"
            />
          </div>,
          <div key="label_kk" className="col-span-12 md:col-span-3">
            <span className="block text-sm font-medium text-neutral-800 mb-2">
              Название (на-казахском)
            </span>
            <Input
              value={data?.label_kk}
              onChange={(e) => {
                setData({
                  ...data,
                  label_kk: e.target.value,
                });
              }}
              placeholder="Введите название страницы"
            />
          </div>,
          <div key="label_en" className="col-span-12 md:col-span-3">
            <span className="block text-sm font-medium text-neutral-800 mb-2">
              Название (на-английском)
            </span>
            <Input
              value={data?.label_en}
              onChange={(e) => {
                setData({
                  ...data,
                  label_en: e.target.value,
                });
              }}
              placeholder="Введите название страницы"
            />
          </div>,
          <div key="label_en" className="col-span-12 md:col-span-3">
            <span className="block text-sm font-medium text-neutral-800 mb-2">
              Название (на-китайском)
            </span>
            <Input
              value={data?.label_cn}
              onChange={(e) => {
                setData({
                  ...data,
                  label_cn: e.target.value,
                });
              }}
              placeholder="Введите название страницы"
            />
          </div>,
        ]}
      </div>
    </Modal>
  );
}
