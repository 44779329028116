import { useEffect, useState } from "react";
import { Table, message, Button, Badge } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { includes } from "ramda";
import { Link } from "react-router-dom";
import View from "../view";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import * as Filters from "./filters";
import * as API from "@api";

dayjs.extend(relativeTime);

export default function DataTable({
  showHeader = true,
  compact = false,
  showFilters = ["search", "status"],
  searchParams = null,
  setSearchParams = () => {},
  pageSize = 10,
  showPagination = true,
}) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState(searchParams?.filter || {});
  const [pagination, setPagination] = useState({
    current: searchParams?.pagination?.current
      ? parseInt(searchParams.pagination.current)
      : 1,
    pageSize,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
    showTotal: (total) => `Найдено: ${total}`,
  });

  useEffect(() => {
    getTableData();
  }, [filter]);

  async function getTableData(params = pagination, _, sorter) {
    setLoading(true);

    setSearchParams({
      filter,
      pagination: {
        current: params.current,
      },
    });

    const _res = await API.Callbacks.getAll({
      filter,
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
      order: sorter?.field && {
        [sorter?.field]: sorter?.order === "ascend" ? "ASC" : "DESC",
      },
    });

    if (!_res.data) {
      return message.error(
        "Ошибка во время загрузки данных, попробуйте позже",
        2
      );
    }

    setTableData(_res.data.rows);
    setPagination({
      ...params,
      total: _res.data.count,
      showTotal: (total) => `Найдено: ${total}`,
    });
    setLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter(
      { ...filter, [f]: v || undefined },
      setPagination({ ...pagination, current: 1 })
    );
  }

  const columns = [
    {
      dataIndex: "info",
      key: "info",
      align: "left",
      title: "Контактные данные",
      width: 300,
      render: (_, all) => (
        <div className="cursor-pointer" onClick={() => setData(all)}>
          <span className="block text-base font-medium text-black leading-tight mb-1">
            {all?.name}
          </span>
          <span className="block text-sm text-neutral-500 leading-tight">
            {all?.phone}
          </span>
        </div>
      ),
    },
    {
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      title: "Дата",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (data) => {
        return dayjs(data).format("DD.MM.YYYY");
      },
      sorter: true,
    },
    {
      dataIndex: "status",
      key: "status",
      align: "center",
      title: "Статус",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (status) => {
        if (status === "new") {
          return <Badge status="warning" text="Новый запрос" />;
        }

        return <Badge status="default" text="Просмотрен" />;
      },
      sorter: true,
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (_, all) => (
        <Button onClick={() => setData(all)} icon={<EditOutlined />}>
          Открыть
        </Button>
      ),
    },
  ];

  return (
    <div className="grid grid-cols-12 gap-4">
      {includes("search", showFilters) && (
        <div className="col-span-12 md:col-span-6">
          <Filters.Search
            defaultValue={filter?.search}
            onChange={onFilterChange}
          />
        </div>
      )}
      {includes("status", showFilters) && (
        <div className="col-span-12 md:col-span-6">
          <Filters.Status
            defaultValue={filter?.active}
            onChange={onFilterChange}
          />
        </div>
      )}
      <div className="col-span-12">
        <Table
          showHeader={showHeader}
          onChange={getTableData}
          pagination={showPagination && pagination}
          dataSource={tableData}
          loading={loading}
          className="rounded-sm overflow-hidden w-full"
          rowKey="id"
          scroll={{ x: "max-content" }}
          columns={columns}
        />
        <View open={!!data} data={data} onCancel={() => setData(null)} />
      </div>
    </div>
  );
}
