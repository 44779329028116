import { Form, Input, Tabs } from "antd";

export default function InputField(props) {
  return (
    <div className="w-full">
      <Form.Item
        name="slug"
        className="w-full mb-5"
        label="URL"
        help="URL заполняется автоматически из названия. Вы можете изменить его вручную, но не рекомендуется из-за риска дублирования."
        rules={[
          {
            required: true,
            message: "Пожалуйста, заполните URL",
          },
        ]}
      >
        <Input
          controls={false}
          className="w-full"
          placeholder="URL заполняется автоматически"
        />
      </Form.Item>
      <Tabs
        defaultActiveKey="ru"
        items={[
          {
            key: "ru",
            label: "Русский",
            forceRender: true,
            children: (
              <div className="flex flex-col gap-4">
                <Form.Item
                  name={["meta", "seo_title"]}
                  className="w-full"
                  label="SEO Заголовок"
                >
                  <Input
                    controls={false}
                    className="w-full"
                    placeholder="Заголовок для поисковых систем"
                  />
                </Form.Item>
                <Form.Item
                  name={["meta", "seo_description"]}
                  className="w-full"
                  label="SEO Описание"
                >
                  <Input.TextArea
                    rows={3}
                    controls={false}
                    className="w-full"
                    placeholder="Описание для поисковых систем"
                  />
                </Form.Item>
              </div>
            ),
          },
          {
            key: "kk",
            label: "Қазақша",
            forceRender: true,
            children: (
              <div className="flex flex-col gap-4">
                <Form.Item
                  name={["meta", "seo_title_kk"]}
                  className="w-full"
                  label="SEO Заголовок"
                >
                  <Input
                    controls={false}
                    className="w-full"
                    placeholder="Заголовок для поисковых систем"
                  />
                </Form.Item>
                <Form.Item
                  name={["meta", "seo_description_kk"]}
                  className="w-full"
                  label="SEO Описание"
                >
                  <Input.TextArea
                    rows={3}
                    controls={false}
                    className="w-full"
                    placeholder="Описание для поисковых систем"
                  />
                </Form.Item>
              </div>
            ),
          },
          {
            key: "en",
            label: "English",
            forceRender: true,
            children: (
              <div className="flex flex-col gap-4">
                <Form.Item
                  name={["meta", "seo_title_en"]}
                  className="w-full"
                  label="SEO Заголовок"
                >
                  <Input
                    controls={false}
                    className="w-full"
                    placeholder="Заголовок для поисковых систем"
                  />
                </Form.Item>
                <Form.Item
                  name={["meta", "seo_description_en"]}
                  className="w-full"
                  label="SEO Описание"
                >
                  <Input.TextArea
                    rows={3}
                    controls={false}
                    className="w-full"
                    placeholder="Описание для поисковых систем"
                  />
                </Form.Item>
              </div>
            ),
          },
          {
            key: "cn",
            label: "中文",
            forceRender: true,
            children: (
              <div className="flex flex-col gap-4">
                <Form.Item
                  name={["meta", "seo_title_cn"]}
                  className="w-full"
                  label="SEO Заголовок"
                >
                  <Input
                    controls={false}
                    className="w-full"
                    placeholder="Заголовок для поисковых систем"
                  />
                </Form.Item>
                <Form.Item
                  name={["meta", "seo_description_cn"]}
                  className="w-full"
                  label="SEO Описание"
                >
                  <Input.TextArea
                    rows={3}
                    controls={false}
                    className="w-full"
                    placeholder="Описание для поисковых систем"
                  />
                </Form.Item>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
