import { Alert } from "antd";
import Widget from "./widget";
import WidgetScript from "./widget_script";

export default function Bookings({ data, form }) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <Widget form={form} />
      </div>
      <div className="col-span-12">
        <Alert
          showIcon
          description="Если выбрана опция «Нативная форма бронирования», страница «Бронирования» (jeyranbalqash.kz/bookings) будет предзаполнена виджетом бронирования. В других случаях, настроить отображение страницы можно в «Страницах» -> Страница бронирования."
        />
      </div>
      <div className="col-span-12">
        <WidgetScript form={form} />
      </div>
      <div className="col-span-12">
        <Alert
          showIcon
          type="warning"
          description="Скрипт виджета необходим только если выбрана опция «Скрипт виджета (Shelter или другой)» выше. В остальных случаях, скрипт не нужен."
        />
      </div>
    </div>
  );
}
