import admin from "./admin";
import reception from "./reception";
import content from "./content";
import { Loading } from "@components";
import { Navigate } from "react-router-dom";
import { useAuth } from "@contexts";

export default function RoutesHandler() {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return [
      {
        path: "*",
        element: <Loading />,
      },
    ];
  }

  if (!currentUser) {
    return [
      {
        path: "*",
        element: <Navigate to="/login" replace />,
      },
    ];
  }

  if (currentUser?.role === "admin") {
    return admin;
  }

  if (currentUser?.role === "reception") {
    return reception;
  }

  if (currentUser?.role === "content-manager") {
    return content;
  }

  return [
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ];
}
