import Photohero from "./photohero";
import Photocards from "./photocards";
import Minioptions from "./minioptions";
import Text from "./text";
import Buttons from "./buttons";
import Heading from "./heading";
import Carousel from "./carousel";
import Slider from "./slider";
import { Alert } from "antd";

export default function Preview({ data, ...props }) {
  switch (data?.type) {
    case "photohero":
      return <Photohero {...data} {...props} />;
    case "text":
      return <Text {...data} {...props} />;
    case "minioptions":
      return <Minioptions {...data} {...props} />;
    case "photocards":
      return <Photocards {...data} {...props} />;
    case "buttons":
      return <Buttons {...data} {...props} />;
    case "heading":
      return <Heading {...data} {...props} />;
    case "carousel":
      return <Carousel {...data} {...props} />;
    case "slider":
      return <Slider {...data} {...props} />;
    case "people":
      return (
        <Alert
          className="w-full"
          message="Виджет «Люди»"
          description="Настроить виджет отображения сотрудников можно на странице Настройки → Команда."
          type="info"
        />
      );
    case "booking":
      return (
        <Alert
          className="w-full"
          message="Виджет бронирования"
          description="Настроить виджет бронирования можно на странице Настройки → Общие."
          type="info"
        />
      );
    case "maps":
      return (
        <Alert
          className="w-full"
          message="Виджет карты"
          description="Настроить Карты можно на странице Настройки → Карты."
          type="info"
        />
      );
    case "fishmap":
      return (
        <Alert
          className="w-full"
          message="Виджет «Рыболовная карта»"
          description="Настроить виджет можно на странице Настройки → Рыболовная карта."
          type="info"
        />
      );
    case "html":
    case "raw":
      return (
        <Alert
          className="w-full"
          message="HTML-код"
          description="HTML-код можно загрузится на самой странице при публикации или во время «Предпросмотра»"
          type="info"
        />
      );

    default:
      break;
  }
}
