import { Divider } from "antd";
import SEO from "./seo";
// import Nav from "./nav";
import Sitemap from "./sitemap";

export default function Details(props) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <Sitemap {...props} />
      </div>
      <div className="col-span-12">
        <Divider className="my-2">SEO</Divider>
      </div>
      <div className="col-span-12">
        <SEO {...props} />
      </div>
    </div>
  );
}
