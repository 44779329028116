import { Button, List, Popconfirm, Divider, Tag } from "antd";
import { useEffect, useState } from "react";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import EditMap from "../edit";

export default function MapList(props) {
  const [map, setMap] = useState(null);
  const [data, setData] = useState(props?.data || []);

  useEffect(() => {
    props.form.setFieldsValue({
      data,
    });
  }, [data]);

  const onMapAdd = () => {
    const newMap = {
      id: new Date().getTime(),
      label: "Маршрут " + (data.length + 1),
      icon: "car",
      type: "script",
      data: null,
    };

    setData([...data, newMap]);

    triggerModal(newMap);
  };

  const onMapChange = (newData) => {
    const index = data.findIndex((item) => item?.id === newData?.id);
    const newDataArray = [...data];
    newDataArray[index] = newData;
    setData(newDataArray);
  };

  const onMapRemove = (key) => {
    const newData = data.filter((_, index) => index !== key);
    setData(newData);
  };

  const onMapPositionChange = (oldIndex, newIndex) => {
    const newData = [...data];
    const item = newData.splice(oldIndex, 1)[0];
    newData.splice(newIndex, 0, item);
    setData(newData);
  };

  const triggerModal = (map) => {
    setMap(map);
  };

  console.log("maps", data);

  return (
    <div className="w-full">
      <List
        dataSource={data}
        renderItem={(item, key) => (
          <List.Item key={key} className="p-0 list-none">
            <div className="w-full grid grid-cols-12 gap-4 py-3">
              <div className="col-span-12 lg:col-span-8">
                <span className="block text-base w-full text-black font-medium">
                  {item?.icon && (
                    <img
                      className="mr-2"
                      src={`/img/icons/${item?.icon}.svg`}
                      alt="Icon"
                    />
                  )}
                  {item?.label}
                </span>
                {item?.type === "script" && (
                  <Tag className="m-0 mt-2" color="blue">
                    HTML-код
                  </Tag>
                )}
                {item?.type === "link" && (
                  <Tag className="m-0 mt-2" color="blue">
                    Ссылка
                  </Tag>
                )}
                {item?.type === "image" && (
                  <Tag className="m-0 mt-2" color="blue">
                    Изображение
                  </Tag>
                )}
              </div>
              <div className="col-span-12 lg:col-span-4 flex justify-end items-center">
                <Button.Group>
                  <Button
                    disabled={key === 0}
                    type="text"
                    icon={<UpOutlined />}
                    onClick={() => onMapPositionChange(key, key - 1)}
                  />
                  <Button
                    disabled={key === data.length - 1}
                    type="text"
                    icon={<DownOutlined />}
                    onClick={() => onMapPositionChange(key, key + 1)}
                  />
                  <Button
                    type="text"
                    icon={<EditOutlined />}
                    onClick={() => triggerModal(item)}
                  />

                  <Popconfirm
                    cancelText="Отмена"
                    onConfirm={() => onMapRemove(key)}
                    okText="Удалить"
                    title="Удалить элемент безвозвратно?"
                  >
                    <Button type="text" danger icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Button.Group>
              </div>
            </div>
          </List.Item>
        )}
        footer={
          <Button
            disabled={data.length >= 50}
            icon={<PlusOutlined />}
            onClick={onMapAdd}
            type="dashed"
            block
          >
            Добавить карту / маршрут
          </Button>
        }
      />
      <EditMap
        data={map}
        open={!!map}
        onCancel={() => triggerModal(null)}
        onChange={onMapChange}
      />
    </div>
  );
}
