import { Button, List, Popconfirm, Tag, Modal } from "antd";
import { useEffect, useState } from "react";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import Edit from "../edit";

export default function NavList(props) {
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [data, setData] = useState(props?.data || []);

  useEffect(() => {
    props.form.setFieldsValue({
      data,
    });
  }, [data]);

  const onNavItemAdd = (navData) => {
    const newData = [...data, navData];
    setData(newData);
    setSelectorOpen(false);
  };

  const onNavItemRemove = (key) => {
    const newData = data.filter((_, index) => index !== key);
    setData(newData);
  };

  const onNavItemPositionChange = (key, newKey) => {
    const newData = [...data];
    const item = newData[key];
    newData.splice(key, 1);
    newData.splice(newKey, 0, item);
    setData(newData);
  };

  const triggerModal = () => {
    setSelectorOpen(!selectorOpen);
  };

  return (
    <div className="w-full">
      <span className="w-full block text-center text-sm text-neutral-500">
        Вы можете менять порядок элементов навигации в верхнем меню, добавлять и
        удалять их.
      </span>
      <List
        dataSource={data}
        renderItem={(item, key) => (
          <List.Item key={key} className="p-0 list-none">
            <div className="w-full grid grid-cols-12 gap-4 py-3">
              <div className="col-span-12 lg:col-span-8">
                <span className="block text-base w-full text-black font-medium">
                  {item?.label}
                </span>
                {item?.type === "page" && (
                  <Tag className="m-0 mt-1" color="blue">
                    Внутренняя страница
                  </Tag>
                )}
                {item?.type === "link" && (
                  <Tag className="m-0 mt-1" color="orange">
                    Внешняя ссылка
                  </Tag>
                )}
              </div>
              <div className="col-span-12 lg:col-span-4 flex justify-end items-center">
                <Button.Group>
                  <Button
                    disabled={key === 0}
                    type="text"
                    icon={<UpOutlined />}
                    onClick={() => onNavItemPositionChange(key, key - 1)}
                  />
                  <Button
                    disabled={key === data.length - 1}
                    type="text"
                    icon={<DownOutlined />}
                    onClick={() => onNavItemPositionChange(key, key + 1)}
                  />
                  <Popconfirm
                    cancelText="Отмена"
                    onConfirm={() => onNavItemRemove(key)}
                    okText="Удалить"
                    title="Удалить элемент безвозвратно?"
                  >
                    <Button type="text" danger icon={<DeleteOutlined />} />
                  </Popconfirm>
                </Button.Group>
              </div>
            </div>
          </List.Item>
        )}
        footer={
          <Button
            disabled={data.length >= 50}
            icon={<PlusOutlined />}
            onClick={triggerModal}
            type="dashed"
            block
          >
            Добавить страницу в навигацию
          </Button>
        }
      />
      <Edit open={selectorOpen} onCancel={triggerModal} onAdd={onNavItemAdd} />
    </div>
  );
}
