import { Form, Input } from "antd";

export default function InputField({ name = "wp_widget_status" }) {
  return (
    <Form.Item
      name={name}
      className="w-full"
      label="Статус оператора"
      rules={[
        {
          required: true,
          message: "Это поле обязательно",
        },
      ]}
    >
      <Input
        controls={false}
        className="w-full"
        placeholder="Введите статус оператора (отображается под именем)"
      />
    </Form.Item>
  );
}
