import classNames from "classnames";
import Buttons from "../buttons";
import config from "@config";

export default function Photohero({ data = null, options, ...props }) {
  return (
    <div
      className={classNames("waves-y relative", {
        "max-w-3xl": options?.width === "small",
        "my-0": options?.margin === "none",
        [options?.class]: options?.class,
      })}
    >
      <img
        style={{ userDrag: "none" }}
        draggable="false"
        src={`${config.api}/static/img/${data.image}`}
        className="block object-cover object-center w-full h-80 md:h-96 lg:h-120"
      />

      <div className="absolute w-full h-full inset-0 m-auto flex flex-col justify-center items-center gap-6">
        {data?.title && (
          <span className="block w-fit h-fit text-center font-medium text-xl sm:text-3xl md:text-5xl text-white drop-shadow-md">
            {data?.title}
          </span>
        )}
        {data?.buttons?.length > 0 && (
          <Buttons data={data} options={{ class: "m-0" }} />
        )}
      </div>
    </div>
  );
}
