import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import AuthContextProvider from "@contexts";
import { ConfigProvider } from "antd";
import ruRU from "antd/locale/ru_RU";
import "antd/dist/reset.css";
import "@styles/global.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider locale={ruRU}>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </ConfigProvider>
);
