import axios from "axios";
import config from "@config";

const API = `${config.api}/admin/services`;

export const getAll = (params) =>
  axios
    .get(`${API}/`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));

export const getOne = (params) =>
  axios
    .get(`${API}/id/${params.id}`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));

export const update = (data) =>
  axios
    .post(`${API}/id/${data.id}`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

export const create = (data) =>
  axios
    .post(`${API}/`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });

export const destroy = (data) =>
  axios
    .delete(`${API}/id/${data.id}`, data, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
