import axios from "axios";
import config from "@config";

const FRONTEND_API = `${config.frontend}/api/revalidate`;
const secret = config.nextRevalidateKey;

export const revalidateAll = (params = {}) =>
  axios
    .get(`${FRONTEND_API}`, {
      params: { secret, ...params },
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));

export const revalidatePage = (params = {}) =>
  axios
    .get(`${FRONTEND_API}-page`, {
      params: { secret, ...params },
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));

export const revalidateRoom = (params = {}) =>
  axios
    .get(`${FRONTEND_API}-room`, {
      params: { secret, ...params },
      withCredentials: false,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));
