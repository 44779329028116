import { Card, Button } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import List from "./list";
import { useEffect, useState } from "react";

export default function EditEntry({ loading, form }) {
  const [data, setData] = useState(form.getFieldValue("data") || {});

  useEffect(() => {
    form.setFieldsValue({
      data,
    });
  }, [data]);

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-9 flex flex-col gap-4">
        <Card title="Данные мест для рыбалки">
          <span className="block w-full text-neutral-600">
            На этой страницы вы можете отредактировать данные для виджета
            «Рыболовная карта».
          </span>

          <List data={data} onChange={setData} />
        </Card>
      </div>
      <div className="col-span-12 md:col-span-3">
        <Card title="Действия">
          <Button
            loading={loading}
            block
            type="primary"
            icon={<SaveOutlined />}
            htmlType="submit"
          >
            Сохранить
          </Button>
        </Card>
      </div>
    </div>
  );

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-9 flex flex-col gap-4">
        <Card title="Данные мест для рыбалки" bordered={false}>
          <div className="grid grid-cols-12 gap-x-4 gap-y-2">
            <div className="col-span-12">
              {/* <Details data={data} form={form} /> */}
            </div>
          </div>
        </Card>
      </div>
      <div className="col-span-12 md:col-span-3">
        <Card title="Действия">
          <Button
            loading={loading}
            block
            type="primary"
            icon={<SaveOutlined />}
            htmlType="submit"
          >
            Сохранить
          </Button>
        </Card>
      </div>
    </div>
  );
}
