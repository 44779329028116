import React from "react";
import pkg from "@base/package.json";
import { Layout, Menu, Dropdown, Avatar } from "antd";
import { Link } from "react-router-dom";
import { useAuth } from "@contexts";
import {
  HomeOutlined,
  TeamOutlined,
  UserOutlined,
  LogoutOutlined,
  PhoneOutlined,
  DashboardOutlined,
  SettingOutlined,
  GlobalOutlined,
  LinkOutlined,
} from "@ant-design/icons";

const { Header, Content, Footer, Sider } = Layout;

export default function Moderator({ children, page, ...props }) {
  const { currentUser } = useAuth();

  return (
    <Layout hasSider={false} style={{ minHeight: "100vh" }}>
      <Header
        className="bg-white shadow-sm flex justify-between items-center p-5 z-50"
        style={{
          height: 70,
          position: "fixed",
          // top: 0,
          zIndex: 1,
          width: "100%",
        }}
      >
        <div className="flex justify-start items-center">
          <img
            src="/img/logo.svg"
            className="block"
            height={40}
            alt="jeyranbalqash.kz"
          />
          <div className="hidden md:block ml-2">
            <span className="block font-semibold text-base leading-tight">
              Jeyran &amp; Fishing Lodge
            </span>
            <span className="block text-sm leading-tight text-neutral-500">
              Система управления сайтом
            </span>
          </div>
        </div>
        <Dropdown
          menu={{
            className: "min-w-44 max-w-xs",
            items: [
              {
                key: "profile",
                type: "group",
                label: (
                  <div className="w-full p-0">
                    <span className="break-words block mb-1 w-full text-neutral-400 font-normal text-xs">
                      ID {currentUser.id} &middot; {currentUser.email}
                    </span>
                  </div>
                ),
              },
              {
                key: "divider",
                type: "divider",
              },
              {
                key: "logout",
                icon: <LogoutOutlined />,
                label: (
                  <Link className="transition-none" to="/logout">
                    Выйти
                  </Link>
                ),
                danger: true,
              },
            ],
          }}
        >
          <div className="flex items-center">
            <Avatar
              style={{ cursor: "pointer" }}
              icon={<UserOutlined />}
              size="large"
            />
            <div className="flex flex-col justify-center items-start ml-3 text-left cursor-pointer">
              <span className="text-base font-medium text-slate-900 leading-none m-0">
                {currentUser?.name}
              </span>
              <span className="text-sm text-neutral-400 leading-none mt-1">
                Ресепшн
              </span>
            </div>
          </div>
        </Dropdown>
      </Header>
      <Layout
        hasSider={false}
        className="ml-16 lg:ml-52"
        style={{
          marginTop: 70,
          backgroundColor: "#fafafb",
        }}
      >
        <Sider
          className="bg-white pt-5"
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            bottom: 0,
          }}
          breakpoint="lg"
          collapsedWidth="64"
          width={208}
        >
          <div
            style={{
              height: 32,
              margin: 16,
            }}
          />
          <Menu
            mode="inline"
            selectedKeys={[page]}
            defaultOpenKeys={["dict", "blog"]}
            items={[
              {
                key: "dashboard",
                icon: <DashboardOutlined />,
                label: (
                  <Link className="transition-none" to="/">
                    Главная
                  </Link>
                ),
              },
              {
                key: "callbacks",
                icon: <PhoneOutlined />,
                label: (
                  <Link className="transition-none" to="/callbacks">
                    Запросы
                  </Link>
                ),
              },
              {
                key: "users",
                icon: <TeamOutlined />,
                label: (
                  <Link className="transition-none" to="/users">
                    Пользователи
                  </Link>
                ),
              },
              {
                type: "divider",
              },
              {
                key: "shelter",
                icon: <LinkOutlined />,
                label: (
                  <Link
                    target="_blank"
                    className="transition-none"
                    to="https://pms.frontdesk24.ru/v1/booklist.html"
                  >
                    Бронирования
                  </Link>
                ),
              },
              {
                type: "divider",
              },
              {
                key: "logout",
                icon: <LogoutOutlined />,
                danger: true,
                label: (
                  <Link className="transition-none" to="/logout">
                    Выйти
                  </Link>
                ),
              },
            ]}
          />
        </Sider>
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div className="bg-none p-0 md:lg-p-3 lg:p-6">{children}</div>
        </Content>
        <Footer
          style={{
            backgroundColor: "#fafafb",
            textAlign: "center",
          }}
        >
          Jeyran &amp; Fishing Lodge © {new Date().getFullYear()} | Разработано
          в{" "}
          <a href="https://musan.kz" alt="Musan Group" target="_blank">
            Musan Digital
          </a>{" "}
          | Версия {pkg.version}
        </Footer>
      </Layout>
    </Layout>
  );
}
