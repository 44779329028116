import CTA from "./cta";
import CTALabel from "./cta_label";
import Callbacks from "./callbacks";

export default function Details({ data, form }) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <CTA form={form} />
      </div>
      <div className="col-span-12">
        <CTALabel form={form} />
      </div>
      <div className="col-span-12">
        <Callbacks form={form} />
      </div>
    </div>
  );
}
