import { Select } from "antd";

export default function FilterItem({ onChange, ...props }) {
  return (
    <Select
      className="w-full"
      placeholder="Статус"
      allowClear
      showSearch
      filterOption={(input, option) =>
        option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(v) => onChange("active", v)}
      options={[
        {
          value: "0",
          label: "Черновик",
        },
        {
          value: "1",
          label: "На сайте",
        },
      ]}
      {...props}
    />
  );
}
