import * as Pages from "@pages";
import Layout from "@modules/layouts/admin";

const routes = [
  {
    path: "/users/view/:id",
    element: (
      <Layout page="users">
        <Pages.Users.View />
      </Layout>
    ),
  },
  {
    path: "/users/edit/:id",
    element: (
      <Layout page="users">
        <Pages.Users.Edit />
      </Layout>
    ),
  },
  {
    path: "/users/new",
    element: (
      <Layout page="users">
        <Pages.Users.New />
      </Layout>
    ),
  },
  {
    path: "/users",
    element: (
      <Layout page="users">
        <Pages.Users.List />
      </Layout>
    ),
  },
  {
    path: "/settings/people",
    element: (
      <Layout page="settings.people">
        <Pages.Settings.People />
      </Layout>
    ),
  },
  {
    path: "/settings/fishmap",
    element: (
      <Layout page="settings.fishmap">
        <Pages.Settings.Fishmap />
      </Layout>
    ),
  },
  {
    path: "/settings/nav",
    element: (
      <Layout page="settings.nav">
        <Pages.Settings.Nav />
      </Layout>
    ),
  },
  {
    path: "/settings/map",
    element: (
      <Layout page="settings.map">
        <Pages.Settings.Map />
      </Layout>
    ),
  },
  {
    path: "/settings/global",
    element: (
      <Layout page="settings.global">
        <Pages.Settings.Global />
      </Layout>
    ),
  },
  {
    path: "/callbacks",
    element: (
      <Layout page="callbacks">
        <Pages.Callbacks.List />
      </Layout>
    ),
  },
  {
    path: "/pages/edit/:id",
    element: (
      <Layout page="pages">
        <Pages.Pages.Edit />
      </Layout>
    ),
  },
  {
    path: "/pages/new",
    element: (
      <Layout page="pages">
        <Pages.Pages.New />
      </Layout>
    ),
  },
  {
    path: "/pages",
    element: (
      <Layout page="pages">
        <Pages.Pages.List />
      </Layout>
    ),
  },
  {
    path: "/rooms/edit/:id",
    element: (
      <Layout page="rooms">
        <Pages.Rooms.Edit />
      </Layout>
    ),
  },
  {
    path: "/rooms/new",
    element: (
      <Layout page="rooms">
        <Pages.Rooms.New />
      </Layout>
    ),
  },
  {
    path: "/rooms",
    element: (
      <Layout page="rooms">
        <Pages.Rooms.List />
      </Layout>
    ),
  },
  {
    path: "/",
    element: (
      <Layout page="dashboard">
        <Pages.Dashboard />
      </Layout>
    ),
  },
];

export default routes;
