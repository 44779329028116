import axios from "axios";
import qs from "qs";
import * as Auth from "./auth";
import * as Callbacks from "./callbacks";
import * as Users from "./users";
import * as Settings from "./settings";
import * as Static from "./static";
import * as Revalidate from "./revalidate";
import * as Pages from "./pages";
import * as Components from "./components";
import * as Rooms from "./rooms";
import * as RoomTypes from "./room_types";
import * as Services from "./services";

axios.defaults.withCredentials = true;

axios.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  };

  config.paramsSerializer = (params) => {
    return qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false,
    });
  };

  return config;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export {
  Auth,
  Callbacks,
  Users,
  Settings,
  Static,
  Revalidate,
  Pages,
  Components,
  Rooms,
  RoomTypes,
  Services,
};
