import { Card, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Rooms } from "@modules";
import { Breadcrumb } from "@components";
import { useSearchParams } from "react-router-dom";
import qs from "qs";

export default function ListPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const setFilter = (filter) => {
    setSearchParams(
      qs.stringify(filter, {
        arrayFormat: "brackets",
        encode: false,
        skipNulls: true,
        addQueryPrefix: true,
      })
    );
  };

  return (
    <div className="container max-content">
      <Breadcrumb data={[{ title: "Номера", href: "/rooms" }]} />
      <div className="flex justify-between items-center flex-wrap gap-4 mb-7">
        <h1 className="text-3xl m-0">Номера</h1>
        <Button type="primary" icon={<PlusOutlined />} href="/rooms/new">
          Добавить номер
        </Button>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Card bordered={false}>
            <Rooms.List
              searchParams={qs.parse(searchParams.toString(), {
                ignoreQueryPrefix: true,
              })}
              setSearchParams={setFilter}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}
