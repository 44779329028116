import classNames from "classnames";
import style from "./style.module.scss";
import config from "@config";
import { Fragment } from "react";

export default function Photocards({ data, className, options, ...props }) {
  const getCardContent = (item) => {
    return (
      <Fragment>
        <div className="waves-y relative">
          <img
            src={`${config.api}/static/img/${item.url}`}
            className={style.img}
            style={{ userDrag: "none" }}
            draggable="false"
          />
          {options?.cover && item?.title && (
            <span
              className={classNames(style.bluelabel, {
                "text-left": options?.align === "left",
                "text-center": !options?.align || options?.align === "center",
                "text-right": options?.align === "right",
              })}
            >
              {item?.title}
            </span>
          )}
        </div>
        {!options?.cover && item?.title && (
          <span
            className={classNames(style.label, {
              "text-left": !options?.align || options?.align === "left",
              "text-center": options?.align === "center",
              "text-right": options?.align === "right",
            })}
          >
            {item?.title}
          </span>
        )}
        {item?.subtitle && (
          <span
            dangerouslySetInnerHTML={{ __html: item?.subtitle }}
            className={classNames(style.subtitle, {
              [style.left]: !options?.align || options?.align === "left",
              [style.center]: options?.align === "center",
              [style.right]: options?.align === "right",
            })}
          />
        )}
      </Fragment>
    );
  };

  return (
    <div
      className={classNames("container content-max", options?.class, {
        "max-w-3xl": options?.width === "small",
        "my-0": options?.margin === "none",
        [options?.class]: options?.class,
      })}
    >
      <div className={classNames(style.container)}>
        {data?.photocards?.map((item, i) => {
          return item?.href ? (
            <a
              href={item?.href}
              rel={item?.href_rel || item?.href_target_blank ? "noopener" : ""}
              target={item?.href_target_blank ? "_blank" : "_self"}
              title={item?.title || "Открыть"}
              key={i}
              className={classNames(
                style.card,
                style.cta,
                "col-span-12 sm:col-span-6",
                {
                  "md:col-span-4": options?.size === "lg" || !options?.size,
                  "md:col-span-3": options?.size === "base",
                }
              )}
            >
              {getCardContent(item)}
            </a>
          ) : (
            <div
              key={i}
              className={classNames(style.card, "col-span-12 sm:col-span-6", {
                "md:col-span-4": options?.size === "lg" || !options?.size,
                "md:col-span-3": options?.size === "base",
              })}
            >
              {getCardContent(item)}
            </div>
          );
        })}
      </div>
    </div>
  );
}
