// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { useState } from "react";
import classNames from "classnames";
import config from "@config";

// Import Swiper styles
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css";

export default function MediaSlider({ data = [], options, ...props }) {
  const [current, setCurrent] = useState(0);

  if (!data || data?.media?.length === 0) {
    return null;
  }

  return (
    <div
      className={classNames("waves-y relative jeyran-swiper my-0", {
        "max-w-3xl": options?.width === "small",
        [options?.class]: options?.class,
      })}
    >
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={5}
        slidesPerView={1}
        // onSlideChange={() => console.log("slide change")}
        onSlideChange={(swiper) => {
          setCurrent(swiper.activeIndex);
        }}
        onSwiper={(swiper) => console.log(swiper)}
        autoHeight
        pagination={{
          bulletClass: "dot",
          bulletActiveClass: "dot-active",
          clickable: true,
          horizontalClass: "custom-pagination",
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
      >
        {data?.media?.map((item, i) => {
          const Tag = item?.href ? "a" : "div";

          return (
            <SwiperSlide
              data-swiper-autoplay={
                item?.type === "video" ? item?.duration : 5000
              }
              key={i}
            >
              <Tag
                href={item?.href}
                rel={
                  item?.href_rel || item?.href_target_blank ? "noopener" : ""
                }
                target={item?.href_target_blank ? "_blank" : "_self"}
                className="relative"
              >
                {item?.type === "video" && (
                  <video
                    autoPlay={item?.autoplay || false}
                    controls={item?.controls || false}
                    muted={item?.muted || false}
                    loop={item?.loop || false}
                    src={`${config.api}/static/video/${item?.url}`}
                    className="block object-cover object-center w-full h-96 lg:h-120"
                    alt={item?.title || "Jeyran"}
                  />
                )}

                {item?.type === "image" && (
                  <img
                    style={{ userDrag: "none" }}
                    draggable="false"
                    src={`${config.api}/static/img/${item?.url}`}
                    className="block object-cover object-center w-full h-96 lg:h-120"
                    alt={item?.title || "Jeyran"}
                  />
                )}

                {item?.title && (
                  <span className="block w-fit h-fit absolute z-10 left-0 right-0 top-0 bottom-0 m-auto text-center font-medium text-lg uppercase text-white drop-shadow-md">
                    {item?.title}
                    <br />{" "}
                    {item?.accent && (
                      <i className="block not-italic font-semibold text-4xl ">
                        {item?.accent}
                      </i>
                    )}
                  </span>
                )}
              </Tag>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
