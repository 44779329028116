import { Divider } from "antd";
import Type from "./type";
import Slider from "./slider";
import SEO from "./seo";
import ShelterId from "./shelterid";

export default function Details(props) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 sm:col-span-6">
        <Type {...props} />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Slider {...props} />
      </div>
      <div className="col-span-12">
        <Divider className="my-2">
          Отображение переводов в форме бронирования
        </Divider>
      </div>
      <div className="col-span-12">
        <ShelterId {...props} />
      </div>
      <div className="col-span-12">
        <Divider className="my-2">SEO</Divider>
      </div>
      <div className="col-span-12">
        <SEO {...props} />
      </div>
    </div>
  );
}
