import { Form, Input } from "antd";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";

export default function InputField(props) {
  return (
    <div className="flex flex-col gap-4">
      <Form.Item
        {...props}
        name="contacts_primary_phone"
        className="w-full"
        label="Основной номер (бронирование, консультации)"
        rules={[
          {
            required: true,
            message: "Пожалуйста, введите номер телефона",
          },
        ]}
      >
        <Input
          addonBefore={<PhoneOutlined />}
          controls={false}
          className="w-full"
          placeholder="Напишите номер телефона (напр. +7 777 000-00-00)"
        />
      </Form.Item>
      <Form.Item
        {...props}
        name="contacts_reception_phone"
        className="w-full"
        label="Номер ресепшн"
        rules={[
          {
            required: true,
            message: "Пожалуйста, введите номер телефона",
          },
        ]}
      >
        <Input
          addonBefore={<PhoneOutlined />}
          controls={false}
          className="w-full"
          placeholder="Напишите номер телефона (напр. +7 777 000-00-00)"
        />
      </Form.Item>
      <Form.Item
        {...props}
        name="contacts_primary_email"
        className="w-full"
        label="Основной email"
        rules={[
          {
            required: true,
            message: "Пожалуйста, введите основную почту",
          },
        ]}
      >
        <Input
          addonBefore={<MailOutlined />}
          controls={false}
          className="w-full"
          placeholder="Напр. info@jeyranbalqash.kz"
        />
      </Form.Item>
    </div>
  );
}
