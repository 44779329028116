import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@components";
import { message, Form } from "antd";
import DataForm from "./form";
import Hashids from "hashids";
import config from "@config";
import * as API from "@api";

export default function EditData() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    API.Pages.getOne({ id }).then((res) => {
      if (res.error || !res) {
        return message.error(
          "Невозможно запросить данные. Пожалуйста, попробуйте снова позже.",
          2
        );
      }

      setData(res.data);
      setLoading(false);
    });
  }, []);

  if (!data) {
    return <Loading compact />;
  }

  const onSave = async (values) => {
    setLoading(true);

    const preview = form.getFieldValue("preview") || false;
    const previewLang = form.getFieldValue("previewLang") || false;
    const components = form.getFieldValue("components") || {};
    const meta = form.getFieldValue("meta");
    const images = form.getFieldValue("images");
    const normalizedValues = Object.entries(values).reduce(
      (acc, [key, value]) => {
        acc[key] = value !== undefined ? value : null;
        return acc;
      },
      {}
    );

    form.resetFields(["preview"]);
    form.resetFields(["previewLang"]);

    API.Pages.update({
      id,
      ...normalizedValues,
      preview: null,
      components,
      meta,
      images,
    })
      .then(async (res) => {
        setLoading(false);
        if (res?.error || !res?.data) {
          throw new Error(res?.message);
        }

        // Revalidate
        try {
          setLoading(true);

          // Revalidate all
          await API.Revalidate.revalidateAll();
          await API.Revalidate.revalidatePage({ slug: res?.data?.slug });

          // if page slug changed, revalidate old page
          if (data?.slug !== res?.data?.slug) {
            await API.Revalidate.revalidatePage({ slug: data?.slug });
          }

          setLoading(false);
        } catch (err) {
          setLoading(false);
          message.error("Не удалось обновить данные на сайте", 2);
        }

        if (preview) {
          const hashids = new Hashids(config.hashidsSalt);
          const suffix = previewLang === "ru" ? "" : `/${previewLang}`;

          return window.open(
            `${
              config.frontend
            }${suffix}/preview?type=page&hash=${hashids.encode(id)}`,
            "_blank"
          );
        }

        // after showing success message, refresh page
        return message.success("Данные успешно обновлены", 1, () =>
          window.location.reload()
        );
      })
      .catch((err) => {
        setLoading(false);
        return message.error(
          err.message || "Данные не удалось обновить, проверье форму отправки",
          2
        );
      });
  };

  const onDelete = () => {
    if (data?.is_reserved) {
      return message.error("Невозможно удалить зарезервированную страницу", 2);
    }

    setLoading(true);

    API.Pages.destroy({ id })
      .then(async (res) => {
        if (res?.error || !res?.data) {
          throw new Error(res?.message);
        }

        // Revalidate
        try {
          setLoading(true);

          // revalidate all
          await API.Revalidate.revalidateAll().then(() => setLoading(false));

          // revalidate page
          await API.Revalidate.revalidatePage({ slug: data?.slug });

          setLoading(false);
        } catch (err) {
          setLoading(false);
          message.error("Не удалось обновить данные на сайте", 2);
        }

        return message.success(
          "Данные успешно удалены",
          1,
          () => (window.location.href = "/pages")
        );
      })
      .catch((err) => {
        setLoading(false);
        return message.error(
          err.message || "Не удалось удалить данные, попробуйте снова позже.",
          2
        );
      });
  };

  return (
    <Form
      form={form}
      onFinish={onSave}
      onFinishFailed={(err) =>
        err?.errorFields?.map((msg) => message.error(msg.errors[0], 2))
      }
      layout="vertical"
      className="w-full form-container"
      initialValues={data}
    >
      <DataForm onDelete={onDelete} form={form} data={data} loading={loading} />
    </Form>
  );
}

//
