import { useEffect, useState } from "react";
import { Loading } from "@components";
import { message } from "antd";
import { Navigate } from "react-router-dom";
import * as API from "@api";

export default function NewPage() {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!data) {
      const date = new Date().toLocaleString();
      API.Pages.create({
        name: `Новая страница ${date}`,
        name_kk: `Новая страница ${date}`,
        name_en: `Новая страница ${date}`,
        show_in_nav: false,
        active: false,
      })
        .then((res) => {
          if (!res || res?.error) {
            throw new Error("Ошибка создания данных");
          }
          setData(res?.data);
        })
        .catch((err) => {
          return message.error(err?.message || "Ошибка создания данных", 2);
        });
    }
  }, [data]);

  if (data?.id) {
    return <Navigate to={`/pages/edit/${data?.id}`} />;
  }

  return <Loading />;
}
