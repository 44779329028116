import { Input, Button, Drawer, Select, Space, Empty } from "antd";
import { useEffect, useState } from "react";
import { Icons } from "@utils";
import {
  UpOutlined,
  DownOutlined,
  EditOutlined,
  DeleteOutlined,
  LinkOutlined,
} from "@ant-design/icons";

export default function Widget({ input, onChange, key, data }) {
  const [options, setOptions] = useState(data?.data?.[input.key] || []);
  const [option, setOption] = useState(null);

  useEffect(() => {
    onChange(input.key, options);
  }, [options]);

  const onOptionAdd = () => {
    setOptions([...options, { label: "Новая опция", icon: "bolt" }]);
    setOption(options.length);
  };

  const onOptionPositionChange = (index, position) => {
    const _options = [...options];
    const item = _options[index];
    _options.splice(index, 1);
    _options.splice(index + position, 0, item);
    setOptions(_options);
  };

  const onOptionDelete = (index) => {
    const _options = [...options];
    _options.splice(index, 1);
    setOptions(_options);
  };

  const getDrawerContent = () => {
    if (option === null) {
      return null;
    }

    const item = options[option];

    return (
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Иконка
          </span>
          <Select
            className="w-full"
            placeholder="Выберите иконку"
            options={Icons.Icons.map((icon) => ({
              label: (
                <span className="flex gap-1 items-center">
                  <img src={`/img/icons/${icon}.svg`} alt="Icon" />
                  {icon}
                </span>
              ),
              description: icon,
              value: icon,
              url: `/img/icons/${icon}.svg`,
            }))}
            onChange={(value) => {
              const _options = [...options];
              _options[option].icon = value;
              setOptions(_options);
            }}
            defaultValue={false}
            value={item?.icon}
            size="large"
            optionRender={(option) => (
              <Space>
                <span role="img" aria-label={option.data.description}>
                  <img
                    src={option.data.url}
                    className="block"
                    alt={option.data.description}
                  />
                </span>
                {option.data.description}
              </Space>
            )}
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Текст
          </span>
          <Input
            value={item?.label}
            onChange={(e) => {
              const _options = [...options];
              _options[option].label = e.target.value;
              setOptions(_options);
            }}
            placeholder="Введите текст опции"
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Детали
          </span>
          <Input
            value={item?.subtitle}
            onChange={(e) => {
              const _options = [...options];
              _options[option].subtitle = e.target.value;
              setOptions(_options);
            }}
            placeholder="Введите детали (отобразится мелким шрифтом)"
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Ссылка при нажатии
          </span>
          <Input
            value={item?.href}
            onChange={(e) => {
              const _options = [...options];
              _options[option].href = e.target.value;
              setOptions(_options);
            }}
            placeholder="Введите ссылку"
            prefix={<LinkOutlined />}
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Открыть ссылку в новой вкладке
          </span>
          <Select
            className="w-full"
            placeholder="Выберите значение"
            options={[
              { label: "Да", value: true },
              { label: "Нет", value: false },
            ]}
            onChange={(value) => {
              const _options = [...options];
              _options[option].href_target_blank = value;
              setOptions(_options);
            }}
            defaultValue={false}
            value={item?.href_target_blank}
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Атрибут ссылки rel
          </span>
          <Input
            value={item?.href_rel}
            onChange={(e) => {
              const _options = [...options];
              _options[option].href_rel = e.target.value;
              setOptions(_options);
            }}
            placeholder="Введите значение (напр. nofollow)"
            prefix={<LinkOutlined />}
          />
        </div>
        <div className="col-span-12">
          <Button
            className="mt-5"
            type="primary"
            block
            onClick={() => setOption(null)}
          >
            Готово
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="col-span-12">
      <span className="block text-xs font-medium text-neutral-800 mb-2">
        {input.label}{" "}
        {input.required && <i className="not-italic text-rose-600">*</i>}
      </span>

      {options?.length > 0 && (
        <div className="p-3 w-full bg-neutral-50 rounded-md mb-5 grid grid-cols-12 gap-y-3">
          {options?.map((o, i) => (
            <div
              key={i}
              className="col-span-12 flex justify-between items-center"
            >
              <div className="flex justify-start items-center gap-2">
                {o?.icon && (
                  <img
                    src={`/img/icons/${o?.icon}.svg`}
                    alt="Icon"
                    className="text-white"
                  />
                )}
                <span className="text-sm">{o?.label}</span>
              </div>
              <Button.Group>
                <Button
                  disabled={i === 0}
                  onClick={() => onOptionPositionChange(i, -1)}
                  size="small"
                  type="text"
                  icon={<UpOutlined />}
                />
                <Button
                  onClick={() => onOptionPositionChange(i, 1)}
                  disabled={i === options.length - 1}
                  size="small"
                  type="text"
                  icon={<DownOutlined />}
                />
                <Button
                  size="small"
                  type="text"
                  onClick={() => setOption(i)}
                  icon={<EditOutlined />}
                />
                <Button
                  size="small"
                  type="text"
                  danger
                  onClick={() => onOptionDelete(i)}
                  icon={<DeleteOutlined />}
                />
              </Button.Group>
            </div>
          ))}
        </div>
      )}

      {options?.length === 0 && (
        <Empty
          className="my-10 max-w-xs mx-auto"
          description="Опций пока нет. Нажмите «Добавить опцию», чтобы добавить."
        />
      )}

      <Button onClick={onOptionAdd} block type="dashed">
        Добавить опцию
      </Button>

      <Drawer
        onClose={() => setOption(null)}
        title="Редактировать опцию"
        open={option !== null}
      >
        {getDrawerContent()}
      </Drawer>
    </div>
  );
}
