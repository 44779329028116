import { Divider } from "antd";
import Social from "./social";
import Main from "./main";

export default function Contacts(props) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <Divider className="my-0" orientation="left">
          Ссылки на соц.сети
        </Divider>
      </div>
      <div className="col-span-12">
        <Social {...props} />
      </div>
      <div className="col-span-12">
        <Divider className="my-0" orientation="left">
          Основные контактные данные
        </Divider>
      </div>
      <div className="col-span-12">
        <Main {...props} />
      </div>
    </div>
  );
}
