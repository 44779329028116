// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import classNames from "classnames";
import config from "@config";

// Import Swiper styles
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css";

export default function MediaSlider({ data, ...props }) {
  return (
    <div
      className={classNames("waves-y relative jeyran-swiper my-0", {
        "max-w-3xl": props?.options?.width === "small",
      })}
    >
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={5}
        slidesPerView={1}
        autoHeight
        pagination={{
          bulletClass: "dot",
          bulletActiveClass: "dot-active",
          clickable: true,
          horizontalClass: "custom-pagination",
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
      >
        {data?.images?.map((item, i) => (
          <SwiperSlide key={i} className="relative">
            <img
              src={`${config.api}/static/img/${item}`}
              className="block object-cover object-center w-full h-96 lg:h-120"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
