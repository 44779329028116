import { Divider, Drawer, Input, Button, InputNumber } from "antd";
import { useEffect, useState } from "react";
import { EditOutlined, CloseOutlined, SaveOutlined } from "@ant-design/icons";
import * as Fish from "./fish";

export default function EditPool({
  open,
  data,
  onClose,
  onChange,
  defaults,
  onDefaultsChange,
}) {
  const [pool, setPool] = useState(data || null);
  const [newFishModal, setNewFishModal] = useState(false);
  const [fish, setFish] = useState(null);

  useEffect(() => {
    if (!open) return;
    setPool(data);
  }, [open]);

  const newFishModalTrigger = () => {
    setNewFishModal(!newFishModal);
  };

  const onNewFishAdd = (fish) => {
    setPool({
      ...pool,
      fish: [...(pool?.fish || []), fish],
    });
    newFishModalTrigger();
  };

  const onFishChange = (editedFish) => {
    setPool({
      ...pool,
      fish: pool?.fish?.map((fish) =>
        fish?.id === editedFish?.id ? editedFish : fish
      ),
    });
    setFish(null);
  };

  const onFishDelete = (idx) => {
    setPool({
      ...pool,
      fish: pool?.fish?.filter((fish, _idx) => _idx !== idx),
    });
  };

  const onPoolInfoChange = (e) => {
    setPool({ ...pool, [e.target.name]: e.target.value });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      width={500}
      title="Редактирование пула"
    >
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-12 flex flex-col gap-2">
          <span className="block w-full text-sm text-black font-medium">
            Название (на-русском)
          </span>
          <Input
            className="w-full"
            value={pool?.label}
            name="label"
            onChange={onPoolInfoChange}
            placeholder="Введите название пула"
          />
        </div>
        <div className="col-span-12 flex flex-col gap-2">
          <span className="block w-full text-sm text-black font-medium">
            Название (на-казахском)
          </span>
          <Input
            className="w-full"
            value={pool?.label_kk}
            name="label_kk"
            onChange={onPoolInfoChange}
            placeholder="Введите название пула"
          />
        </div>
        <div className="col-span-12 flex flex-col gap-2">
          <span className="block w-full text-sm text-black font-medium">
            Название (на-английском)
          </span>
          <Input
            className="w-full"
            value={pool?.label_en}
            name="label_en"
            onChange={onPoolInfoChange}
            placeholder="Введите название пула"
          />
        </div>
        <div className="col-span-12 flex flex-col gap-2">
          <span className="block w-full text-sm text-black font-medium">
            Название (на-китайском)
          </span>
          <Input
            className="w-full"
            value={pool?.label_cn}
            name="label_cn"
            onChange={onPoolInfoChange}
            placeholder="Введите название пула"
          />
        </div>
        <div className="col-span-12 flex flex-col gap-2">
          <span className="block w-full text-sm text-black font-medium">
            Глубина (м.)
          </span>
          <InputNumber
            min={0}
            className="w-full"
            value={pool?.depth}
            name="depth"
            onChange={(depth) => setPool({ ...pool, depth })}
            placeholder="Введите глубину"
          />
        </div>
        <div className="col-span-12 flex flex-col gap-2">
          <span className="block w-full text-sm text-black font-medium">
            Глубина макс. (м.)
          </span>
          <InputNumber
            min={pool?.depth || 0}
            className="w-full"
            value={pool?.depth_max}
            name="depth_max"
            onChange={(depth_max) => setPool({ ...pool, depth_max })}
            placeholder="Введите макс. глубину"
          />
        </div>
        <div className="col-span-12">
          <Divider className="my-2" />
        </div>
        <div className="col-span-12">
          <span className="block w-full text-sm text-black font-medium">
            Рыба
          </span>
          <div className="bg-neutral-50 rounded-lg p-3 mt-3 flex flex-col gap-3">
            {pool?.fish?.map((poolFish, idx) => (
              <div key={idx} className="grid grid-cols-12 gap-4">
                <div className="col-span-12 md:col-span-6">
                  <span className="block text-sm w-full font-medium">
                    {
                      // filter fish by ids in defaults and get labels
                      defaults?.fish_types
                        ?.filter((fish) =>
                          poolFish?.fish_types?.includes(fish?.id)
                        )
                        ?.map((fish) => fish?.label)
                        ?.join(", ")
                    }
                  </span>
                  <span className="block text-sm w-full text-neutral-500">
                    {
                      // filter fishing styles by ids in defaults and get labels
                      defaults?.fishing_styles
                        ?.filter((fish) =>
                          poolFish?.fishing_styles?.includes(fish?.id)
                        )
                        ?.map((fish) => fish?.label)
                        ?.join(", ")
                    }
                  </span>
                </div>
                <div className="col-span-12 md:col-span-6 flex justify-end items-center">
                  <Button.Group size="small">
                    <Button
                      onClick={() => setFish(poolFish)}
                      type="text"
                      icon={<EditOutlined />}
                    />
                    <Button
                      onClick={() => onFishDelete(idx)}
                      type="text"
                      danger
                      icon={<CloseOutlined />}
                    />
                  </Button.Group>
                </div>
              </div>
            ))}
            <Button onClick={newFishModalTrigger} block>
              Добавить рыбу
            </Button>
          </div>
        </div>
        <div className="col-span-12">
          <Divider className="my-2" />
        </div>
        <div className="col-span-12">
          <Button
            disabled={
              !pool?.label ||
              !pool?.label_kk ||
              !pool?.label_en ||
              !pool?.label_cn ||
              !pool?.depth
            }
            block
            type="primary"
            icon={<SaveOutlined />}
            onClick={() => onChange(pool)}
          >
            Сохранить изменения
          </Button>
        </div>
      </div>
      <Fish.New
        onAdd={onNewFishAdd}
        open={newFishModal}
        onCancel={newFishModalTrigger}
        defaults={defaults}
        onDefaultsChange={onDefaultsChange}
      />
      <Fish.Edit
        open={!!fish}
        data={fish}
        onChange={onFishChange}
        onCancel={() => setFish(null)}
        defaults={defaults}
        onDefaultsChange={onDefaultsChange}
      />
    </Drawer>
  );
}
