import { Tabs } from "antd";
import Phone from "./phone";
import Name from "./name";
import Status from "./status";
import Message from "./message";
import Display from "./display";
import Avatar from "./avatar";

export default function Details({ data, form }) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <Avatar form={form} />
      </div>
      <div className="col-span-12">
        <Display form={form} />
      </div>
      <div className="col-span-12">
        <Phone form={form} />
      </div>
      <div className="col-span-12">
        <Tabs
          items={[
            {
              key: "ru",
              label: "Русский",
              children: (
                <div className="flex flex-col gap-4">
                  <Name form={form} name="wp_widget_name" />
                  <Status form={form} name="wp_widget_status" />
                  <Message form={form} name="wp_widget_message" />
                </div>
              ),
              forceRender: true,
            },
            {
              key: "kk",
              label: "Қазақша",
              children: (
                <div className="flex flex-col gap-4">
                  <Name form={form} name="wp_widget_name_kk" />
                  <Status form={form} name="wp_widget_status_kk" />
                  <Message form={form} name="wp_widget_message_kk" />
                </div>
              ),
              forceRender: true,
            },
            {
              key: "en",
              label: "English",
              children: (
                <div className="flex flex-col gap-4">
                  <Name form={form} name="wp_widget_name_en" />
                  <Status form={form} name="wp_widget_status_en" />
                  <Message form={form} name="wp_widget_message_en" />
                </div>
              ),
              forceRender: true,
            },
            {
              key: "cn",
              label: "中文",
              children: (
                <div className="flex flex-col gap-4">
                  <Name form={form} name="wp_widget_name_cn" />
                  <Status form={form} name="wp_widget_status_cn" />
                  <Message form={form} name="wp_widget_message_cn" />
                </div>
              ),
              forceRender: true,
            },
          ]}
        />
      </div>
    </div>
  );
}
