import classNames from "classnames";
import style from "./style.module.scss";

export default function Text({ className, ...props }) {
  console.log("props", props);
  const Tag = props.options?.tag || "div";

  return (
    <div
      className={classNames("container content-max", {
        [props?.options?.class]: props?.options?.class,
      })}
    >
      <div
        className={classNames("w-full mx-auto flex flex-col gap-y-2", {
          "max-w-3xl": props?.options?.width === "small",
          "my-0": props?.options?.margin === "none",
          "my-10": props?.options?.margin === "small",
          "my-10 md:my-16 lg:my-28": props?.options?.margin === "medium",
          "my-12 md:my-20 lg:my-32": props?.options?.margin === "large",
        })}
      >
        {props?.data?.title && (
          <Tag
            className={classNames(style.title, {
              "text-center": props?.options?.align === "center",
              "text-left": props?.options?.align === "left",
              "text-right": props?.options?.align === "right",
              "!mb-0": props?.data?.accent,
              [style[props?.options?.size || "base"]]:
                props?.options?.size || "base",
            })}
          >
            {props?.data?.title}
          </Tag>
        )}

        {props?.data?.accent && (
          <span
            className={classNames(style.subtitle, {
              "text-center": props?.options?.align === "center",
              "text-left": props?.options?.align === "left",
              "text-right": props?.options?.align === "right",
            })}
          >
            {props?.data?.accent}
          </span>
        )}

        {props?.options?.html && (
          <div dangerouslySetInnerHTML={{ __html: props?.options?.html }} />
        )}
      </div>
    </div>
  );
}
