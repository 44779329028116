import { Card, Button } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import Details from "./details";

export default function EditEntry({ data, loading, form }) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-9 flex flex-col gap-4">
        <Card title="Навигация в верхнем меню" bordered={false}>
          <div className="grid grid-cols-12 gap-x-4 gap-y-2">
            <div className="col-span-12">
              <Details data={data} form={form} />
            </div>
          </div>
        </Card>
      </div>
      <div className="col-span-12 md:col-span-3">
        <Card title="Действия">
          <Button
            loading={loading}
            block
            type="primary"
            icon={<SaveOutlined />}
            htmlType="submit"
          >
            Сохранить
          </Button>
        </Card>
      </div>
    </div>
  );
}
