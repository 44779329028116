import { HTMLEditor } from "@components";

export default function Editor({ input, onChange, key, data }) {
  return (
    <div className="col-span-12">
      <span className="block text-xs font-medium text-neutral-800 mb-2">
        {input.label}{" "}
        {input.required && <i className="not-italic text-rose-600">*</i>}
      </span>
      <HTMLEditor
        value={data?.data?.[input.key] || ""}
        onChange={(data) => onChange(input.key, data)}
      />
    </div>
  );
}
