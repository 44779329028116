import { Form, Select } from "antd";

export default function InputField(props) {
  return (
    <Form.Item
      {...props}
      name="role"
      className="w-full"
      label="Роль"
      rules={[
        {
          required: true,
          message: "Пожалуйста, выберите роль",
        },
      ]}
    >
      <Select
        placeholder="Выберите роль"
        className="w-full"
        options={[
          {
            label: "Администратор (полный доступ)",
            value: "admin",
          },
          {
            label: "Контент-менеджер",
            value: "content-manager",
          },
          {
            label: "Ресепшн",
            value: "reception",
          },
        ]}
      />
    </Form.Item>
  );
}
