import { Modal, Input, Select, Space } from "antd";
import { useState, useEffect } from "react";
import { LinkOutlined } from "@ant-design/icons";
import { Icons } from "@utils";
import Image from "./image";

export default function EditMap({ open, data, onCancel, onChange, ...props }) {
  const [map, setMap] = useState(data || null);

  useEffect(() => {
    if (!map) return;
    onChange(map);
  }, [map]);

  useEffect(() => {
    console.log("stopper");
    if (!data) return;
    setMap(data);
  }, [data]);

  useEffect(() => {
    console.log("stopper");
    // clear data on type change
    if (data?.type !== map?.type) {
      setMap({
        ...map,
        data: null,
      });
    }
  }, [map?.type]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      centered
      width={900}
      title="Редактировать карту"
      cancelButtonProps={{ hidden: true }}
      okText="Сохранить"
      onOk={onCancel}
    >
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Иконка
          </span>
          <Select
            className="w-full"
            placeholder="Выберите иконку"
            options={Icons.Icons.map((icon) => ({
              label: (
                <span className="flex gap-1 items-center">
                  <img src={`/img/icons/${icon}.svg`} alt="Icon" />
                  {icon}
                </span>
              ),
              description: icon,
              value: icon,
              url: `/img/icons/${icon}.svg`,
            }))}
            onChange={(value) => {
              setMap({
                ...map,
                icon: value,
              });
            }}
            value={map?.icon}
            size="large"
            optionRender={(option) => (
              <Space>
                <span role="img" aria-label={option.data.description}>
                  <img
                    src={option.data.url}
                    className="block"
                    alt={option.data.description}
                  />
                </span>
                {option.data.description}
              </Space>
            )}
          />
        </div>
        <div className="col-span-12 md:col-span-3">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Название (на-русском)
          </span>
          <Input
            value={map?.label}
            onChange={(e) => {
              setMap({
                ...map,
                label: e.target.value,
              });
            }}
            placeholder="Введите название карты"
          />
        </div>
        <div className="col-span-12 md:col-span-3">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Название (на-казахском)
          </span>
          <Input
            value={map?.label_kk}
            onChange={(e) => {
              setMap({
                ...map,
                label_kk: e.target.value,
              });
            }}
            placeholder="Введите название карты"
          />
        </div>
        <div className="col-span-12 md:col-span-3">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Название (на-английском)
          </span>
          <Input
            value={map?.label_en}
            onChange={(e) => {
              setMap({
                ...map,
                label_en: e.target.value,
              });
            }}
            placeholder="Введите название карты"
          />
        </div>
        <div className="col-span-12 md:col-span-3">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Название (на-китайском)
          </span>
          <Input
            value={map?.label_cn}
            onChange={(e) => {
              setMap({
                ...map,
                label_cn: e.target.value,
              });
            }}
            placeholder="Введите название карты"
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Тип карты
          </span>
          <Select
            className="w-full"
            placeholder="Выберите тип"
            allowClear={false}
            options={[
              {
                label: "HTML-код",
                value: "script",
              },
              {
                label: "Ссылка",
                value: "link",
              },
              {
                label: "Изображение",
                value: "image",
              },
            ]}
            onChange={(value) => {
              setMap({
                ...map,
                type: value,
              });
            }}
            value={map?.type}
          />
        </div>
        {map?.type === "script" && (
          <div className="col-span-12">
            <span className="block text-sm font-medium text-neutral-800 mb-2">
              HTML-код
            </span>
            <Input.TextArea
              rows={4}
              value={map?.data}
              onChange={(e) => {
                setMap({
                  ...map,
                  data: e.target.value,
                });
              }}
              placeholder="Введите HTML-код"
            />
          </div>
        )}
        {map?.type === "link" && (
          <div className="col-span-12">
            <span className="block text-sm font-medium text-neutral-800 mb-2">
              Внешняя ссылка
            </span>
            <Input
              value={map?.data}
              onChange={(e) => {
                setMap({
                  ...map,
                  data: e.target.value,
                });
              }}
              placeholder="Введите ссылку"
              prefix={<LinkOutlined />}
            />
          </div>
        )}
        {map?.type === "image" && (
          <Image
            data={map?.data}
            onChange={(value) => {
              setMap({
                ...map,
                data: value,
              });
            }}
          />
        )}
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Растянуть на всю ширину?
          </span>
          <Select
            className="w-full"
            placeholder="Выберите значение"
            allowClear={false}
            options={[
              {
                label: "Да",
                value: true,
              },
              {
                label: "Нет",
                value: false,
              },
            ]}
            onChange={(value) => {
              setMap({
                ...map,
                full_width: value,
              });
            }}
            value={map?.full_width}
          />
        </div>
      </div>
    </Modal>
  );
}
