import { Button, Empty, Modal, Popconfirm } from "antd";
import {
  PlusOutlined,
  UpOutlined,
  DownOutlined,
  EditOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import NewCanalModal from "../new";
import EditCanalModal from "../edit";

export default function WidgetList({ data, onChange }) {
  const [editCanalModal, setEditCanalModal] = useState(null);
  const [newCanalModal, setNewCanalModal] = useState(false);

  const onCanalAdd = (newCanal) => {
    onChange({
      ...data,
      canals: [...(data?.canals || []), newCanal],
    });
    setNewCanalModal(false);
    setEditCanalModal(newCanal);
  };

  const onCanalChange = (canal) => {
    const index = data?.canals?.findIndex((item) => item?.id === canal?.id);
    const newData = [...data?.canals];
    newData[index] = canal;
    onChange({
      ...data,
      canals: newData,
    });
    setEditCanalModal(null);
  };

  const onDefaultsChange = (defaults) => {
    onChange({
      ...data,
      defaults,
    });
  };

  const triggerNewCanalModal = () => {
    setNewCanalModal(!newCanalModal);
  };

  const triggerEditCanalModal = (canal = null) => {
    setEditCanalModal(canal);
  };

  const onCanalPositionChange = (oldIndex, newIndex) => {
    const newData = [...data?.canals];
    const item = newData.splice(oldIndex, 1)[0];
    newData.splice(newIndex, 0, item);
    onChange({
      ...data,
      canals: newData,
    });
  };

  const onCanalDelete = (idx) => {
    onChange({
      ...data,
      canals: data?.canals?.filter((canal, _idx) => _idx !== idx),
    });
  };

  return (
    <div className="p-3 m-0 bg-neutral-50 rounded-lg my-5">
      <span className="block w-full text-sm font-medium mb-3">Протоки</span>
      {(data?.canals?.length === 0 || !data?.canals) && (
        <Empty
          className="my-5"
          description="Пока нет протоков. Добавьте проток, нажав на «Добавить новый проток»"
        />
      )}
      <div className="grid grid-cols-12 gap-2">
        {data?.canals?.map((item, index) => (
          <div className="col-span-12 flex justify-between items-center">
            <div>
              <span className="block w-full text-base font-medium">
                {item?.label}
              </span>
              <span className="block w-full text-sm text-neutral-500">
                Пулы: {item?.pools?.map((pool) => pool?.label)?.join(", ")}
              </span>
            </div>
            <Button.Group>
              <Button
                type="text"
                disabled={index === 0}
                onClick={() => onCanalPositionChange(index, index - 1)}
                icon={<UpOutlined />}
              />
              <Button
                type="text"
                disabled={index === data?.canals?.length - 1}
                onClick={() => onCanalPositionChange(index, index + 1)}
                icon={<DownOutlined />}
              />
              <Button
                type="text"
                onClick={() => triggerEditCanalModal(item)}
                icon={<EditOutlined />}
              />
              <Popconfirm
                okText="Да, удалить"
                title="Удалить проток?"
                description="Проток будет удален безвозвратно. Вы уверены?"
                onConfirm={() => onCanalDelete(index)}
              >
                <Button type="text" danger icon={<CloseOutlined />} />
              </Popconfirm>
            </Button.Group>
          </div>
        ))}
        <div className="col-span-12">
          <Button
            icon={<PlusOutlined />}
            onClick={triggerNewCanalModal}
            block
            type="dashed"
          >
            Добавить новый проток
          </Button>
        </div>
      </div>
      <NewCanalModal
        open={newCanalModal}
        onAdd={onCanalAdd}
        onCancel={triggerNewCanalModal}
      />
      <EditCanalModal
        open={!!editCanalModal}
        onCancel={() => setEditCanalModal(null)}
        data={editCanalModal}
        onChange={onCanalChange}
        onDefaultsChange={onDefaultsChange}
        defaults={data?.defaults}
      />
    </div>
  );
}
