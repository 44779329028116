import { Form, Select, Input } from "antd";
import { MailOutlined } from "@ant-design/icons";

export default function InputField(props) {
  return (
    <div className="flex flex-col gap-4">
      <Form.Item
        {...props}
        name="callback_to_email"
        className="w-full"
        label="Дублировать запросы обратного звонка на почту"
        rules={[
          {
            required: true,
            message: "Пожалуйста, выберите опцию",
          },
        ]}
      >
        <Select
          placeholder="Выберите опцию"
          className="w-full"
          options={[
            {
              label: "Да",
              value: true,
            },
            {
              label: "Нет",
              value: false,
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        {...props}
        name="callback_email"
        className="w-full"
        help="Сюда будут дублироваться запросы обратного звонка"
        label="Почта для обратного звонка"
        rules={[
          {
            required: true,
            message: "Пожалуйста, введите почтовый адрес",
          },
        ]}
      >
        <Input
          addonBefore={<MailOutlined />}
          controls={false}
          className="w-full"
          placeholder="Напр. info@jeyranbalqash.kz"
        />
      </Form.Item>
    </div>
  );
}
