import { Form, Input } from "antd";

export default function InputField(props) {
  return (
    <div className="flex flex-col gap-4">
      <Form.Item
        name="cta_label"
        className="w-full"
        label="Текст кнопки в верхнем меню"
        rules={[
          {
            required: true,
            message: "Это поле обязательно",
          },
        ]}
      >
        <Input
          controls={false}
          className="w-full"
          placeholder="Введите название"
        />
      </Form.Item>
      <Form.Item
        name="cta_label_kk"
        className="w-full"
        label="Текст кнопки в верхнем меню (казахский)"
        rules={[
          {
            required: true,
            message: "Это поле обязательно",
          },
        ]}
      >
        <Input
          controls={false}
          className="w-full"
          placeholder="Введите название"
        />
      </Form.Item>
      <Form.Item
        name="cta_label_en"
        className="w-full"
        label="Текст кнопки в верхнем меню (английский)"
        rules={[
          {
            required: true,
            message: "Это поле обязательно",
          },
        ]}
      >
        <Input
          controls={false}
          className="w-full"
          placeholder="Введите название"
        />
      </Form.Item>
      <Form.Item
        name="cta_label_cn"
        className="w-full"
        label="Текст кнопки в верхнем меню (китайский)"
        rules={[
          {
            required: true,
            message: "Это поле обязательно",
          },
        ]}
      >
        <Input
          controls={false}
          className="w-full"
          placeholder="Введите название"
        />
      </Form.Item>
    </div>
  );
}
