import { Alert, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import FishTypes from "./fishtypes";
import FishingStyles from "./fishingstyles";

const MONTHS = [
  {
    id: 1,
    label: "Январь",
    label_kk: "Қаңтар",
    label_en: "January",
    label_cn: "一月份",
  },
  {
    id: 2,
    label: "Февраль",
    label_kk: "Ақпан",
    label_en: "February",
    label_cn: "二月",
  },
  {
    id: 3,
    label: "Март",
    label_kk: "Наурыз",
    label_en: "March",
    label_cn: "三月",
  },
  {
    id: 4,
    label: "Апрель",
    label_kk: "Сәуір",
    label_en: "April",
    label_cn: "四月",
  },
  {
    id: 5,
    label: "Май",
    label_kk: "Мамыр",
    label_en: "May",
    label_cn: "五月份",
  },
  {
    id: 6,
    label: "Июнь",
    label_kk: "Маусым",
    label_en: "June",
    label_cn: "六月",
  },
  {
    id: 7,
    label: "Июль",
    label_kk: "Шілде",
    label_en: "July",
    label_cn: "七月",
  },
  {
    id: 8,
    label: "Август",
    label_kk: "Тамыз",
    label_en: "August",
    label_cn: "八月",
  },
  {
    id: 9,
    label: "Сентябрь",
    label_kk: "Қыркүйек",
    label_en: "September",
    label_cn: "九月",
  },
  {
    id: 10,
    label: "Октябрь",
    label_kk: "Қазан",
    label_en: "October",
    label_cn: "十月",
  },
  {
    id: 11,
    label: "Ноябрь",
    label_kk: "Қараша",
    label_en: "November",
    label_cn: "十一月",
  },
  {
    id: 12,
    label: "Декабрь",
    label_kk: "Желтоқсан",
    label_en: "December",
    label_cn: "十二月",
  },
];

export default function EditFish({
  open,
  onCancel,
  data,
  onChange,
  defaults,
  onDefaultsChange,
}) {
  const [fish, setFish] = useState(data || null);
  const [fishTypesModal, setFishTypesModal] = useState(false);
  const [fishingStylesModal, setFishingStylesModal] = useState(false);

  useEffect(() => {
    if (!open) return;
    setFish(data);
  }, [open]);

  const triggerFishTypesModal = () => {
    setFishTypesModal(!fishTypesModal);
  };

  const triggerFishingStylesModal = () => {
    setFishingStylesModal(!fishingStylesModal);
  };

  return (
    <Modal
      okText="Сохранить"
      cancelText="Отменить"
      open={open}
      centered
      onCancel={onCancel}
      width={750}
      onOk={() => onChange(fish)}
      title="Редактирование рыбы"
      okButtonProps={{
        disabled:
          !fish?.fish_types?.length ||
          !fish?.fishing_styles?.length ||
          !fish?.months?.length,
      }}
    >
      <div className="grid grid-cols-12 gap-3">
        <Alert
          className="col-span-12"
          description="Добавьте коллекцию рыб для данного пула. Каждая коллекция рыб может иметь информацию о месяцах и типе рыбалки."
        />
        <div className="col-span-12 flex flex-col gap-2 my-2">
          <span className="block w-full text-sm text-black font-medium">
            Рыбы
          </span>
          <Select
            mode="multiple"
            className="w-full"
            placeholder="Выберите рыбу (можно выбрать несколько)"
            value={fish?.fish_types}
            name="fish_types"
            onChange={(value) => setFish({ ...fish, fish_types: value })}
            options={defaults?.fish_types?.map((fishtype) => ({
              label: fishtype?.label,
              value: fishtype?.id,
            }))}
            optionFilterProp="label"
          />
          <span className="block w-full text-sm text-neutral-600">
            Не нашли нужный вид рыбы? Добавьте или измените вид рыб{" "}
            <a onClick={triggerFishTypesModal}>здесь</a>
          </span>
        </div>
        <div className="col-span-12 flex flex-col gap-2">
          <span className="block w-full text-sm text-black font-medium">
            Тип рыбалки
          </span>
          <Select
            mode="multiple"
            className="w-full"
            placeholder="Выберите вид рыбалки (можно выбрать несколько)"
            value={fish?.fishing_styles}
            name="fishing_styles"
            onChange={(value) => setFish({ ...fish, fishing_styles: value })}
            options={defaults?.fishing_styles?.map((fishingstyle) => ({
              label: fishingstyle?.label,
              value: fishingstyle?.id,
            }))}
            optionFilterProp="label"
          />
          <span className="block w-full text-sm text-neutral-600">
            Не нашли нужный вид рыбалки? Добавьте или измените вид рыбалки{" "}
            <a onClick={triggerFishingStylesModal}>здесь</a>
          </span>
        </div>
        <div className="col-span-12 flex flex-col gap-2">
          <span className="block w-full text-sm text-black font-medium">
            Месяцы
          </span>
          <Select
            mode="multiple"
            className="w-full"
            placeholder="Выберите месяцы для данной коллекции рыб/рыбалки (можно выбрать несколько)"
            value={fish?.months}
            name="months"
            onChange={(value) => setFish({ ...fish, months: value })}
            options={MONTHS?.map((month) => ({
              label: month?.label,
              value: month?.id,
            }))}
            optionFilterProp="label"
          />
        </div>
      </div>
      <FishTypes
        open={fishTypesModal}
        onCancel={triggerFishTypesModal}
        defaults={defaults}
        onDefaultsChange={onDefaultsChange}
      />
      <FishingStyles
        open={fishingStylesModal}
        onCancel={triggerFishingStylesModal}
        defaults={defaults}
        onDefaultsChange={onDefaultsChange}
      />
    </Modal>
  );
}
