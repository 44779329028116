import React, { createContext, useContext, useEffect, useState } from "react";
import { Auth } from "@api";
import { pathOr } from "ramda";
import { message } from "antd";

const AuthContext = createContext({
  currentUser: null,
  signInWithGoogle: () => Promise,
  login: () => Promise,
  register: () => Promise,
  logout: () => Promise,
  resetPassword: () => Promise,
  loading: false,
});

export const useAuth = () => useContext(AuthContext);

export default function AuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function checkAuth() {
      Auth.getAuth()
        .then((res) => {
          if (res?.data && !res?.error) {
            setCurrentUser(res.data);
          }
        })
        .then(() => setLoading(false));
    }

    checkAuth();
  }, []);

  async function login({ email, password, token = null }) {
    if (!token) {
      return message.error(
        "Error verifying reCaptcha, please try again or refresh this page",
        2
      );
    }

    setLoading(true);
    const user = await Auth.signin({ email, password, token });
    setLoading(false);
    if (user.error || !user) {
      return message.error("Неверные данные, попробуйте снова", 2);
    }

    setCurrentUser({
      email: pathOr(null, ["data", "user", "email"], user),
      name: pathOr(null, ["data", "user", "display_name"], user),
      id: pathOr(null, ["data", "user", "id"], user),
      role: pathOr(null, ["data", "user", "role"], user),
    });
  }

  async function resetPassword(data) {
    setLoading(true);

    Auth.resetPass(data)
      .then(() => {
        message.info(
          "Письмо с инструкцией по сбросу пароля направлено на почту",
          2
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function logout() {
    setLoading(true);
    await Auth.logout();
    setCurrentUser(null);
    setLoading(false);
  }

  const value = {
    currentUser,
    login,
    logout,
    resetPassword,
    loading,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
