import { Image as AntImage, message, Button } from "antd";
import { LoadingOutlined, PictureOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import config from "@config";
import * as API from "@api";

export default function Widget({ onChange, data }) {
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState(data || null);

  useEffect(() => {
    if (!data) return setPhoto(null);
    setPhoto(data);
  }, [data]);

  useEffect(() => {
    onChange(photo);
  }, [photo]);

  const { getRootProps, getInputProps } = useDropzone({
    disabled: loading || photo,
    multiple: false,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpeg", ".jpg"],
    },
    onDrop: async (file) => {
      if (Array.isArray(file)) {
        file = file[0];
      }

      if (file.size > 5 * 1000 * 1000) {
        return message.error("Изображения не должны превышать 5 мегабайт");
      }

      try {
        setLoading(true);
        const _res = await API.Static.uploadImage({
          file,
        });

        if (!_res || !_res?.name) {
          throw new Error();
        }

        setLoading(false);
        setPhoto(_res?.name);
        return message.success("Изображение загружено успешно");
      } catch (error) {
        setLoading(false);
        return message.error(
          `Не удалось загрузить изображение, попробуйте позже`,
          2
        );
      }
    },
  });

  const onImageDelete = (idx) => {
    setPhoto(null);
  };

  return (
    <div className="col-span-12 py-5">
      {photo ? (
        <div className="flex flex-col justify-center items-center">
          <AntImage
            width={100}
            height={100}
            src={`${config.api}/static/img/${photo}`}
            className="object-cover object-center rounded-full overflow-hidden mx-auto"
          />
          <Button
            onClick={onImageDelete}
            className="my-2 w-fit"
            block
            danger
            type="text"
          >
            Удалить
          </Button>
        </div>
      ) : (
        <Fragment>
          <div
            className="ant-upload ant-upload-drag cursor-pointer hover:border-primary transition-long hover:transition-long bg-neutral-50 border-dashed border border-neutral-300 m-0 p-0 flex flex-col justify-center items-center w-24 h-24 rounded-full mx-auto"
            {...getRootProps()}
          >
            <input
              multiple={false}
              {...getInputProps()}
              accept=".png, .jpg, .jpeg"
            />
            <p className="mx-auto block w-fit text-3xl m-0 text-primary">
              {loading ? <LoadingOutlined /> : <PictureOutlined />}
            </p>
          </div>
          <span
            hidden={data}
            className="block mx-auto text-center text-black text-sm mt-2"
          >
            Добавьте фото
          </span>
        </Fragment>
      )}
    </div>
  );
}
