import { Form, Input } from "antd";

export default function InputField(props) {
  return (
    <Form.Item {...props} className="w-full" label="Подзаголовок">
      <Input
        controls={false}
        className="w-full"
        placeholder="Введите подзаголовок (необязательно)"
      />
    </Form.Item>
  );
}
