import {
  Image as AntImage,
  message,
  Button,
  Drawer,
  Input,
  Select,
} from "antd";
import {
  LoadingOutlined,
  PictureOutlined,
  YoutubeOutlined,
  EditOutlined,
  CloseOutlined,
  RightOutlined,
  LeftOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { remove } from "ramda";

import config from "@config";
import * as API from "@api";

export default function Widget({ input, onChange, key, data }) {
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState(data?.data?.[input.key] || []);
  const [current, setCurrent] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    disabled: loading,
    multiple: false,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpeg", ".jpg"],
      "video/mp4": [".mp4"],
      "video/webm": [".webm"],
      "video/ogg": [".ogg"],
    },
    onDrop: async (file) => {
      if (Array.isArray(file)) {
        file = file[0];
      }

      if (file.type.includes("video")) {
        if (file.size > 20 * 1000 * 1000) {
          return message.error("Файл не должен превышать 20 мегабайт");
        }

        try {
          setLoading(true);
          const _res = await API.Static.uploadVideo({
            file,
          });

          if (!_res || !_res?.name) {
            throw new Error();
          }

          setLoading(false);
          setMedia([
            ...media,
            {
              url: _res?.name,
              duration: _res?.duration || 5000,
              type: "video",
              autoplay: true,
              loop: true,
              muted: true,
              controls: false,
              alt: "",
            },
          ]);
          return message.success("Видео загружено успешно");
        } catch (error) {
          setLoading(false);
          return message.error(
            `Не удалось загрузить видео, попробуйте позже`,
            2
          );
        }
      }

      if (file.type.includes("image")) {
        if (file.size > 10 * 1000 * 1000) {
          return message.error("Файл не должен превышать 10 мегабайт");
        }

        try {
          setLoading(true);
          const _res = await API.Static.uploadImage({
            file,
          });

          if (!_res || !_res?.name) {
            throw new Error();
          }

          setLoading(false);
          setMedia([
            ...media,
            {
              url: _res?.name,
              type: "image",
              autoplay: true,
              loop: true,
              muted: true,
              controls: false,
              alt: "",
            },
          ]);
          return message.success("Изображение загружено успешно");
        } catch (error) {
          setLoading(false);
          return message.error(
            `Не удалось загрузить изображение, попробуйте позже`,
            2
          );
        }
      }
    },
  });

  useEffect(() => {
    onChange(input.key, media);
  }, [media]);

  const onPositionChange = (idx, direction) => {
    if (direction === "left") {
      if (idx === 0) {
        return;
      }

      const _media = [...media];
      const _item = _media[idx];
      _media[idx] = _media[idx - 1];
      _media[idx - 1] = _item;

      setMedia(_media);
    }

    if (direction === "right") {
      if (idx === media.length - 1) {
        return;
      }

      const _media = [...media];
      const _item = _media[idx];
      _media[idx] = _media[idx + 1];
      _media[idx + 1] = _item;

      setMedia(_media);
    }
  };

  const onMediaDelete = (idx) => {
    setMedia(remove(idx, 1, media));
  };

  const getMedia = (item, idx) => {
    if (item?.type !== "video" && item?.type !== "image") {
      return null;
    }

    return (
      <div key={idx} className="col-span-12 md:col-span-6">
        {item?.type === "image" ? (
          <AntImage
            width="100%"
            height={150}
            src={`${config.api}/static/img/${item.url}`}
            className="object-cover object-center block"
          />
        ) : (
          <video
            width="100%"
            height={150}
            src={`${config.api}/static/video/${item.url}`}
            className="object-cover object-center"
            controls
          />
        )}
        <Button.Group className="w-full">
          <Button
            size="small"
            type="text"
            disabled={idx === 0}
            className="w-full"
            icon={<LeftOutlined />}
            onClick={() => onPositionChange(idx, "left")}
          />
          <Button
            size="small"
            type="text"
            className="w-full"
            icon={<EditOutlined />}
            onClick={() => setCurrent(idx)}
          />
          <Button
            size="small"
            type="text"
            className="w-full"
            onClick={() => onMediaDelete(idx)}
            danger
            icon={<CloseOutlined />}
          />
          <Button
            size="small"
            type="text"
            disabled={idx === media.length - 1}
            className="w-full"
            icon={<RightOutlined />}
            onClick={() => onPositionChange(idx, "right")}
          />
        </Button.Group>
      </div>
    );
  };

  const getDrawerContent = () => {
    if (current === null) {
      return null;
    }

    const item = media[current];

    return (
      <div className="grid grid-cols-12 gap-4">
        <div hidden={item?.type !== "video"} className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Автоматическое воспроизведение видео
          </span>
          <Select
            className="w-full"
            placeholder="Выберите значение"
            options={[
              { label: "Да", value: true },
              { label: "Нет", value: false },
            ]}
            onChange={(value) => {
              const _media = [...media];
              _media[current].autoplay = value;
              setMedia(_media);
            }}
            value={item?.autoplay}
          />
        </div>
        <div hidden={item?.type !== "video"} className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Зациклить видео
          </span>
          <Select
            className="w-full"
            placeholder="Выберите значение"
            options={[
              { label: "Да", value: true },
              { label: "Нет", value: false },
            ]}
            onChange={(value) => {
              const _media = [...media];
              _media[current].loop = value;
              setMedia(_media);
            }}
            value={item?.loop}
          />
        </div>
        <div hidden={item?.type !== "video"} className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Убрать звук
          </span>
          <Select
            className="w-full"
            placeholder="Выберите значение"
            options={[
              { label: "Да", value: true },
              { label: "Нет", value: false },
            ]}
            onChange={(value) => {
              const _media = [...media];
              _media[current].muted = value;
              setMedia(_media);
            }}
            value={item?.muted}
          />
        </div>
        <div hidden={item?.type !== "video"} className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Показывать панель управления
          </span>
          <Select
            className="w-full"
            placeholder="Выберите значение"
            options={[
              { label: "Да", value: true },
              { label: "Нет", value: false },
            ]}
            onChange={(value) => {
              const _media = [...media];
              _media[current].controls = value;
              setMedia(_media);
            }}
            value={item?.controls}
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Текст (в центре, на фоне медиа)
          </span>
          <Input
            value={item?.title}
            onChange={(e) => {
              const _media = [...media];
              _media[current].title = e.target.value;
              setMedia(_media);
            }}
            placeholder="Введите текст"
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Подзаголовок (в центре, на фоне медиа)
          </span>
          <Input
            value={item?.accent}
            onChange={(e) => {
              const _media = [...media];
              _media[current].accent = e.target.value;
              setMedia(_media);
            }}
            placeholder="Введите подзаголовок"
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Ссылка при нажатии
          </span>
          <Input
            value={item?.href}
            onChange={(e) => {
              const _media = [...media];
              _media[current].href = e.target.value;
              setMedia(_media);
            }}
            placeholder="Введите ссылку"
            prefix={<LinkOutlined />}
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Открыть ссылку в новой вкладке
          </span>
          <Select
            className="w-full"
            placeholder="Выберите значение"
            options={[
              { label: "Да", value: true },
              { label: "Нет", value: false },
            ]}
            onChange={(value) => {
              const _media = [...media];
              _media[current].href_target_blank = value;
              setMedia(_media);
            }}
            defaultValue={false}
            value={item?.href_target_blank}
          />
        </div>
        <div className="col-span-12">
          <span className="block text-sm font-medium text-neutral-800 mb-2">
            Атрибут ссылки rel
          </span>
          <Input
            value={item?.href_rel}
            onChange={(e) => {
              const _media = [...media];
              _media[current].href_rel = e.target.value;
              setMedia(_media);
            }}
            placeholder="Введите значение (напр. nofollow)"
            prefix={<LinkOutlined />}
          />
        </div>
        <div className="col-span-12">
          <Button
            className="mt-5"
            type="primary"
            block
            onClick={() => setCurrent(null)}
          >
            Готово
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="col-span-12">
      <span className="block text-xs font-medium text-neutral-800 mb-2">
        Медиа-слайдер{" "}
        {input.required && <i className="not-italic text-rose-600">*</i>}
      </span>

      <div className="grid grid-cols-12 gap-2">
        {media?.map((item, idx) => getMedia(item, idx))}
        <div hidden={media?.length >= 20} className="col-span-12 md:col-span-6">
          <div
            className="ant-upload ant-upload-drag cursor-pointer hover:border-primary transition-long hover:transition-long bg-neutral-50 border-dashed border border-neutral-300 rounded-md m-0 py-5"
            {...getRootProps()}
          >
            <input
              multiple={false}
              {...getInputProps()}
              accept=".png, .jpg, .jpeg"
            />
            <p className="mx-auto block w-fit text-3xl mb-3 text-primary">
              {loading ? (
                <LoadingOutlined />
              ) : (
                <span className="flex gap-2">
                  <PictureOutlined />
                  <YoutubeOutlined />
                </span>
              )}
            </p>
            <p className="text-sm text-black w-full block text-center mb-0">
              Добавить фото или видео
            </p>
            <p className="text-xs text-neutral-500 w-full block text-center m-0">
              Макс. размер 10 МБ (фото) и 20 МБ (видео)
            </p>
          </div>
        </div>
      </div>
      <Drawer
        placement="right"
        open={!!current || current === 0}
        onClose={() => setCurrent(null)}
        title="Отображение медиа-файла"
      >
        {getDrawerContent()}
      </Drawer>
    </div>
  );
}
