import { useEffect, useState } from "react";
import { Table, message, Button, Badge, Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { includes } from "ramda";
import { Link } from "react-router-dom";
import config from "@config";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import * as API from "@api";
import * as Filters from "./filters";

dayjs.extend(relativeTime);

export default function DataTable({
  showHeader = true,
  compact = false,
  showFilters = ["search", "type", "status"],
  searchParams = null,
  setSearchParams = () => {},
  pageSize = 10,
  showPagination = true,
}) {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState(searchParams?.filter || {});
  const [pagination, setPagination] = useState({
    current: searchParams?.pagination?.current
      ? parseInt(searchParams.pagination.current)
      : 1,
    pageSize,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
    showTotal: (total) => `Найдено: ${total}`,
  });

  useEffect(() => {
    getTableData();
  }, [filter]);

  async function getTableData(params = pagination, _, sorter) {
    setLoading(true);

    setSearchParams({
      filter,
      pagination: {
        current: params.current,
      },
    });

    const _res = await API.Rooms.getAll({
      filter,
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
      order: sorter?.field && {
        [sorter?.field]: sorter?.order === "ascend" ? "ASC" : "DESC",
      },
    });

    if (!_res.data) {
      return message.error(
        "Ошибка во время загрузки данных, попробуйте позже",
        2
      );
    }

    setTableData(_res.data.rows);
    setPagination({
      ...params,
      total: _res.data.count,
      showTotal: (total) => `Найдено: ${total}`,
    });
    setLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter(
      { ...filter, [f]: v || undefined },
      setPagination({ ...pagination, current: 1 })
    );
  }

  const columns = [
    {
      dataIndex: "preview_image",
      key: "preview_image",
      align: "center",
      width: 80,
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (preview_image) => (
        <img
          loading="lazy"
          src={
            preview_image
              ? `${config.api}/static/img/thumb-${preview_image}`
              : "/img/no-img.svg"
          }
          alt="Фото номера"
          className="block mx-auto w-28 object-cover rounded-sm bg-white"
        />
      ),
    },
    {
      dataIndex: "info",
      key: "info",
      align: "left",
      title: "Номер",
      width: 400,
      render: (_, data) => (
        <Link to={`/rooms/edit/${data.id}`}>
          <div className="flex justify-start items-center flex-wrap">
            <span className="block text-sm text-primary">
              {data?.type?.name || "Нет типа"}
            </span>
          </div>
          <span className="block text-base font-medium text-black leading-tight my-1">
            {data?.name}
          </span>
          <span className="block text-sm text-neutral-500 leading-tight">
            {data?.headline}
          </span>
        </Link>
      ),
    },
    {
      dataIndex: "active",
      key: "active",
      align: "center",
      title: "Статус",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (data) => (
        <Badge
          status={data ? "success" : "error"}
          text={data ? "На сайте" : "черновик"}
        />
      ),
      sorter: true,
    },
    {
      dataIndex: "slug",
      key: "slug",
      align: "center",
      title: "URL",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (data) => <Tag className="m-0">/{data}</Tag>,
      sorter: true,
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      responsive: compact ? [] : ["lg", "xl", "xxl"],
      render: (_, all) => (
        <Link to={`/rooms/edit/${all.id}`}>
          <Button icon={<EditOutlined />}>Открыть</Button>
        </Link>
      ),
    },
  ];

  return (
    <div className="grid grid-cols-12 gap-4">
      {includes("search", showFilters) && (
        <div className="col-span-12 md:col-span-4">
          <Filters.Search
            defaultValue={filter?.search}
            onChange={onFilterChange}
          />
        </div>
      )}
      {includes("type", showFilters) && (
        <div className="col-span-12 md:col-span-4">
          <Filters.Type
            defaultValue={filter?.type_id}
            onChange={onFilterChange}
          />
        </div>
      )}
      {includes("status", showFilters) && (
        <div className="col-span-12 md:col-span-4">
          <Filters.Status
            defaultValue={filter?.active}
            onChange={onFilterChange}
          />
        </div>
      )}
      <div className="col-span-12">
        <Table
          showHeader={showHeader}
          onChange={getTableData}
          pagination={showPagination && pagination}
          dataSource={tableData}
          loading={loading}
          className="rounded-sm overflow-hidden w-full"
          rowKey="id"
          scroll={{ x: "max-content" }}
          columns={columns}
        />
      </div>
    </div>
  );
}
