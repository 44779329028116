import { Form, Input } from "antd";

export default function InputField({ name = "wp_widget_name" }) {
  return (
    <Form.Item
      name={name}
      className="w-full"
      label="Имя оператора"
      rules={[
        {
          required: true,
          message: "Это поле обязательно",
        },
      ]}
    >
      <Input
        controls={false}
        className="w-full"
        placeholder="Введите имя оператора (отображается в чате)"
      />
    </Form.Item>
  );
}
