import { Input } from "antd";

export default function Widget({ input, onChange, key, data }) {
  return (
    <div className="col-span-12">
      <span className="block text-xs font-medium text-neutral-800 mb-2">
        {input.label}{" "}
        {input.required && <i className="not-italic text-rose-600">*</i>}
      </span>
      <Input
        value={data?.data?.[input.key] || ""}
        onChange={(e) => onChange(input.key, e.target.value)}
        key={`${input.key}-${key}`}
        placeholder={"Введите " + input.label}
        required={input?.required}
      />
    </div>
  );
}
