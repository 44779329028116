import { Form, Select } from "antd";

export default function InputField(props) {
  return (
    <Form.Item
      {...props}
      name="wp_widget_display"
      className="w-full"
      label="Показывать кнопку на сайте?"
      rules={[
        {
          required: true,
          message: "Это поле обязательно",
        },
      ]}
    >
      <Select
        placeholder="Выберите опцию"
        className="w-full"
        options={[
          { label: "Да", value: true },
          { label: "Нет", value: false },
        ]}
      />
    </Form.Item>
  );
}
