import { Form, Select } from "antd";

export default function InputField(props) {
  return (
    <Form.Item
      {...props}
      name="footer_show_terms"
      className="w-full"
      label="Показывать ссылку на «Пользовательское соглашение»"
    >
      <Select
        placeholder="Выберите опцию"
        className="w-full"
        options={[
          {
            label: "Да",
            value: true,
          },
          {
            label: "Нет",
            value: false,
          },
        ]}
      />
    </Form.Item>
  );
}
