import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useEffect, useState } from "react";

export default function Editor({ input, onChange, key, data }) {
  const [code, setCode] = useState(data?.data?.[input.key] || "");

  useEffect(() => {
    onChange(input.key, code);
  }, [code]);

  return (
    <div className="col-span-12">
      <span className="block text-xs font-medium text-neutral-800 mb-2">
        {input.label}{" "}
        {input.required && <i className="not-italic text-rose-600">*</i>}
      </span>
      <div className="relative flex">
        <textarea
          className="absolute inset-0 resize-none bg-transparent p-2 m-0 font-mono caret-black text-transparent outline-none"
          value={code}
          style={{ minHeight: 30 }}
          onChange={(e) => setCode(e.target.value)}
        />
        <SyntaxHighlighter
          language="xml"
          style={docco}
          customStyle={{
            flex: "1",
            padding: 8,
          }}
        >
          {code}
        </SyntaxHighlighter>
      </div>
    </div>
  );
}
