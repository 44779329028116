import { Form, Select } from "antd";

export default function InputField(props) {
  return (
    <Form.Item
      {...props}
      name="booking_widget_action"
      className="w-full"
      label="Функция формы «Бронирование»"
      rules={[
        {
          required: true,
          message: "Пожалуйста, выберите опцию",
        },
      ]}
    >
      <Select
        placeholder="Выберите опцию"
        className="w-full"
        options={[
          {
            label: "Скрипт виджета (Shelter или другой)",
            value: "shelter_widget",
          },
          {
            label: "Нативная форма бронирования (Jeyran + Shelter)",
            value: "native",
          },
          {
            label: "Показ формы обратного звонка",
            value: "callback_form",
          },
          {
            label: "Не показывать форму",
            value: "hidden",
          },
        ]}
      />
    </Form.Item>
  );
}
