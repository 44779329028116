import { Form, Select, message } from "antd";
import { useEffect, useState } from "react";
import { RoomTypes } from "@api";

export default function FormItem(props) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    RoomTypes.getAll({ limit: 100 }).then((res) => {
      if (res.error || !res) {
        return message.error(
          "Невозможно запросить данные. Пожалуйста, попробуйте снова позже.",
          2
        );
      }

      setOptions(res.data.rows);
    });
  }, []);

  return (
    <Form.Item
      {...props}
      name="type_id"
      className="w-full"
      label="Тип комнаты"
      rules={[
        {
          required: true,
          message: "Валюта обязательна",
        },
      ]}
    >
      <Select
        placeholder="Выберите тип комнаты"
        className="w-full"
        allowClear={false}
        showSearch
        filterOption={(input, option) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={options.map((val) => {
          return {
            label: `${val.name}`,
            value: val.id,
          };
        })}
      />
    </Form.Item>
  );
}
