import { Form, Input } from "antd";

export default function InputField({ name = "wp_widget_message" }) {
  return (
    <Form.Item
      name={name}
      className="w-full"
      label="Приветственное сообщение"
      rules={[
        {
          required: true,
          message: "Это поле обязательно",
        },
      ]}
    >
      <Input.TextArea
        controls={false}
        rows={3}
        className="w-full"
        placeholder="Введите приветственное сообщение"
      />
    </Form.Item>
  );
}
