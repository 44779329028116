import { Image as AntImage, message, Button } from "antd";
import { LoadingOutlined, PictureOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import config from "@config";
import * as API from "@api";

export default function Widget({ input, onChange, key, data }) {
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState(data?.data?.[input.key] || null);

  const { getRootProps, getInputProps } = useDropzone({
    disabled: loading || photo,
    multiple: false,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpeg", ".jpg"],
    },
    onDrop: async (file) => {
      if (Array.isArray(file)) {
        file = file[0];
      }

      if (file.size > 5 * 1000 * 1000) {
        return message.error("Изображения не должны превышать 5 мегабайт");
      }

      try {
        setLoading(true);
        const _res = await API.Static.uploadImage({
          file,
        });

        if (!_res || !_res?.name) {
          throw new Error();
        }

        setLoading(false);
        setPhoto(_res?.name);
        return message.success("Изображение загружено успешно");
      } catch (error) {
        setLoading(false);
        return message.error(
          `Не удалось загрузить изображение, попробуйте позже`,
          2
        );
      }
    },
  });

  useEffect(() => {
    onChange(input.key, photo);
  }, [photo]);

  const onImageDelete = (idx) => {
    setPhoto(null);
  };

  return (
    <div className="col-span-12">
      <span className="block text-xs font-medium text-neutral-800 mb-2">
        Изображение{" "}
        {input.required && <i className="not-italic text-rose-600">*</i>}
      </span>
      {photo ? (
        <div>
          <AntImage
            width="100%"
            height={300}
            src={`${config.api}/static/img/${photo}`}
            className="object-cover object-center"
          />
          <Button
            onClick={onImageDelete}
            className="my-2"
            block
            danger
            type="text"
          >
            Удалить
          </Button>
        </div>
      ) : (
        <div
          className="ant-upload ant-upload-drag cursor-pointer hover:border-primary transition-long hover:transition-long bg-neutral-50 border-dashed border border-neutral-300 rounded-md m-0 py-5"
          {...getRootProps()}
        >
          <input
            multiple={false}
            {...getInputProps()}
            accept=".png, .jpg, .jpeg"
          />
          <p className="mx-auto block w-fit text-5xl mb-3 text-primary">
            {loading ? <LoadingOutlined /> : <PictureOutlined />}
          </p>
          <p className="text-sm text-black w-full block text-center mb-0">
            Добавить изображение
          </p>
          <p className="text-xs text-neutral-500 w-full block text-center m-0">
            Максимальный размер 5 мегабайт
          </p>
        </div>
      )}
    </div>
  );
}
