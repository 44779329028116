import List from "./list";

export default function Details({ data, form }) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <List data={data} form={form} />
      </div>
    </div>
  );
}
