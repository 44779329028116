import Buttons from "../buttons";
import classNames from "classnames";
import style from "./style.module.scss";

export default function Text({ className, ...props }) {
  return (
    <div
      className={classNames("container content-max", {
        [props?.options?.class]: props?.options?.class,
      })}
    >
      <div
        className={classNames("w-full mx-auto", {
          "max-w-3xl": props?.options?.width === "small",
          "my-0": props?.options?.margin === "none",
        })}
      >
        {props?.data?.title && (
          <h2
            className={classNames(style.title, {
              "text-center": props?.options?.titlealign === "center",
              "text-left": props?.options?.titlealign === "left",
              "text-right": props?.options?.titlealign === "right",
              "!mb-0": props?.data?.accent,
            })}
          >
            {props?.data?.title}
          </h2>
        )}
        {props?.data?.accent && (
          <h2
            className={classNames(style.subtitle, {
              "text-center": props?.options?.titlealign === "center",
              "text-left": props?.options?.titlealign === "left",
              "text-right": props?.options?.titlealign === "right",
            })}
          >
            {props?.data?.accent}
          </h2>
        )}

        {props?.data?.copy && (
          <div
            className={classNames([
              style.copy,
              style[props?.options?.textalign || "left"],
            ])}
            dangerouslySetInnerHTML={{ __html: props?.data?.copy }}
          />
        )}

        {props?.data?.buttons?.length > 0 && (
          <Buttons
            data={props?.data}
            options={{ class: "!mt-10", align: props?.options?.textalign }}
          />
        )}
      </div>
    </div>
  );
}
