import classNames from "classnames";

export default function Buttons({ data, locale = "ru", ...props }) {
  return (
    <div
      {...props}
      className={classNames(
        "container content-max flex flex-wrap items-center gap-4",
        {
          "max-w-3xl": props?.options?.width === "small",
          "my-0": props?.options?.margin === "none",
          "justify-center":
            props?.options?.align === "center" || !props?.options?.align,
          "justify-start": props?.options?.align === "left",
          "justify-end": props?.options?.align === "right",
          "!px-0":
            props?.options?.align === "left" ||
            props?.options?.align === "right",
          [props?.options?.class]: props?.options?.class,
        }
      )}
    >
      {data?.buttons?.map((item, key) => (
        <a
          href={item?.href}
          target={item?.href_target_blank ? "_blank" : "_self"}
          key={key}
          rel={item?.href_rel || item?.href_target_blank ? "noopener" : ""}
          className="button-outline active w-fit"
        >
          {item?.icon && (
            <img
              style={{ userDrag: "none" }}
              draggable="false"
              src={`/img/icons/${item?.icon}.svg`}
            />
          )}
          {item?.label}
        </a>
      ))}
    </div>
  );
}
