import { Input } from "antd";

export default function Search({ onSearch }) {
  return (
    <Input.Search
      placeholder="Поиск по имени или почте"
      allowClear
      onSearch={onSearch}
      enterButton="Найти"
    />
  );
}
