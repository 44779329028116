import Terms from "./terms";
import Privacy from "./privacy";
import Callback from "./callback";

export default function Footer({ data, form }) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <Terms form={form} />
      </div>
      <div className="col-span-12">
        <Privacy form={form} />
      </div>
      <div className="col-span-12">
        <Callback form={form} />
      </div>
    </div>
  );
}
