import axios from "axios";
import config from "@config";

const API = `${config.api}/admin/static`;

export const getImg = (params) =>
  axios
    .get(`${API}/img/${params.img}`, {
      params,
      withCredentials: true,
    })
    .then((res) => res.data)
    .catch((err) => ({
      error: true,
      message: err.message || err?.response?.data || "Server error",
    }));

export const uploadImage = (data) => {
  const form = new FormData();
  form.append("file", data?.file);

  return axios
    .post(`${API}/img`, form, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
};

export const uploadVideo = (data) => {
  const form = new FormData();
  form.append("file", data?.file);

  return axios
    .post(`${API}/video`, form, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
};

export const uploadImages = (data) => {
  const form = new FormData();
  data.files.map((file) => form.append("files", file));

  return axios
    .post(`${API}/img/multiple`, form, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err.response.data || { error: true, message: err.message });
};
