import { useState, useEffect } from "react";
import { Loading } from "@components";
import { message } from "antd";
import List from "./list";
import * as API from "@api";

export default function ContentEditor({ data = [], onChange, ...props }) {
  const [loading, setLoading] = useState(true);
  const [blocks, setBlocks] = useState(data || []);
  const [widgets, setWidgets] = useState([]);

  useEffect(() => {
    API.Components.getAll({ limit: 100 })
      .then((components) => {
        setWidgets(components?.data?.rows || []);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        return message.error(err.message || "Ошибка, попробуйте позже", 2);
      });
  }, []);

  useEffect(() => {
    onChange(blocks);
  }, [blocks]);

  useEffect(() => {
    if (data?.length > 0) {
      setBlocks(data);
    }
  }, [data]);

  if (!widgets || widgets?.length === 0) {
    return <Loading compact />;
  }

  return (
    <List
      blocks={blocks}
      onChange={setBlocks}
      widgets={widgets}
      loading={loading}
      key={props?.locale}
      {...props}
    />
  );
}
