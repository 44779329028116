import * as Pages from "@pages";
import Layout from "@modules/layouts/reception";

const routes = [
  {
    path: "/users/view/:id",
    element: (
      <Layout page="users">
        <Pages.Users.View />
      </Layout>
    ),
  },
  {
    path: "/users/edit/:id",
    element: (
      <Layout page="users">
        <Pages.Users.Edit />
      </Layout>
    ),
  },
  {
    path: "/users",
    element: (
      <Layout page="users">
        <Pages.Users.List />
      </Layout>
    ),
  },
  {
    path: "/callbacks",
    element: (
      <Layout page="callbacks">
        <Pages.Callbacks.List />
      </Layout>
    ),
  },
  {
    path: "/",
    element: (
      <Layout page="dashboard">
        <Pages.Dashboard />
      </Layout>
    ),
  },
];

export default routes;
