import { Form, Input } from "antd";

export default function Name(props) {
  return (
    <Form.Item
      {...props}
      name="display_name"
      className="w-full"
      label="Имя"
      rules={[
        {
          required: true,
          message: "Имя обязательно для заполнения",
        },
      ]}
    >
      <Input className="w-full" placeholder="Введите имя пользователя" />
    </Form.Item>
  );
}
