import { Modal, Input } from "antd";
import { useEffect, useState } from "react";

export default function NewCanal({ open, onCancel, onAdd }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!open) return setData(null);
  }, [open]);

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={() =>
        onAdd({
          ...data,
          id: Date.now(),
        })
      }
      cancelText="Отменить"
      okText="Добавить"
      centered
      width={900}
      title="Добавление нового протока"
      okButtonProps={{
        disabled:
          !data?.label || !data?.label_kk || !data?.label_en || !data?.label_cn,
      }}
    >
      <div className="grid grid-cols-12 gap-3 my-5">
        <div className="col-span-12 md:col-span-3 flex flex-col gap-2">
          <span className="block w-full text-sm text-black font-medium">
            Название (на-русском)
          </span>
          <Input
            className="w-full"
            value={data?.label}
            name="label"
            onChange={onChange}
            placeholder="Введите название протока"
          />
        </div>
        <div className="col-span-12 md:col-span-3 flex flex-col gap-2">
          <span className="block w-full text-sm text-black font-medium">
            Название (на-казахском)
          </span>
          <Input
            className="w-full"
            value={data?.label_kk}
            name="label_kk"
            onChange={onChange}
            placeholder="Введите название протока"
          />
        </div>
        <div className="col-span-12 md:col-span-3 flex flex-col gap-2">
          <span className="block w-full text-sm text-black font-medium">
            Название (на-английском)
          </span>
          <Input
            className="w-full"
            value={data?.label_en}
            name="label_en"
            onChange={onChange}
            placeholder="Введите название протока"
          />
        </div>
        <div className="col-span-12 md:col-span-3 flex flex-col gap-2">
          <span className="block w-full text-sm text-black font-medium">
            Название (на-китайском)
          </span>
          <Input
            className="w-full"
            value={data?.label_cn}
            name="label_cn"
            onChange={onChange}
            placeholder="Введите название протока"
          />
        </div>
      </div>
    </Modal>
  );
}
