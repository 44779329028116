import { Form, Input } from "antd";

export default function Password(props) {
  return (
    <div>
      <Form.Item
        {...props}
        name="new_password"
        className="w-full"
        label="Новый пароль"
      >
        <Input.Password
          autoComplete="off"
          className="w-full"
          placeholder="Введите новый пароль"
        />
      </Form.Item>
      <Form.Item
        {...props}
        name="confirm_password"
        className="w-full"
        label="Повторите пароль"
      >
        <Input.Password
          autoComplete="off"
          className="w-full"
          placeholder="Повторите пароль"
        />
      </Form.Item>
    </div>
  );
}
