import Name from "./name";
import Email from "./email";
import Status from "./status";
import Role from "./role";
import { useAuth } from "@contexts";

export default function Details() {
  const { currentUser } = useAuth();

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <Name />
      </div>
      <div className="col-span-12">
        <Email />
      </div>
      <div hidden={currentUser?.role !== "admin"} className="col-span-12">
        <Status />
      </div>
      <div hidden={currentUser?.role !== "admin"} className="col-span-12">
        <Role />
      </div>
    </div>
  );
}
