import { DatePicker } from "antd";

export default function Dates({ onChange }) {
  return (
    <DatePicker.RangePicker
      placeholder={["Создан от", "до"]}
      onChange={(d) => {
        if (!d) {
          return onChange("created_at", null);
        }

        onChange("created_at", {
          from: d[0].format("YYYY-MM-DD"),
          to: d[1].format("YYYY-MM-DD"),
        });
      }}
      format="DD.MM.YYYY"
      className="w-full"
    />
  );
}
