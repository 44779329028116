import { Card, Button, List, Badge } from "antd";
import { Link } from "react-router-dom";
import {
  PlusOutlined,
  TeamOutlined,
  ShopOutlined,
  SettingOutlined,
  CompassOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useAuth } from "@contexts";
import classNames from "classnames";
import Fade from "react-reveal/Fade";
import * as API from "@api";

export default function Dashboard() {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    Promise.all([
      API.Callbacks.getAll({ limit: 5 }),
      API.Rooms.getAll({ limit: 5 }),
      API.Pages.getAll({ limit: 5, filter: { is_main: 0 } }),
    ]).then(([callbacks, rooms, pages]) => {
      setData({
        callbacks: callbacks?.data?.rows || [],
        rooms: rooms?.data?.rows || [],
        pages: pages?.data?.rows || [],
      });
      setLoading(false);
    });
  }, []);

  return (
    <div className="container content-max">
      <h1 className="mb-8 text-3xl">Главная</h1>
      <Fade bottom distance="30px" cascade duration={400}>
        <div className="grid grid-cols-12 gap-4">
          <div
            className={classNames({
              "col-span-12 md:col-span-6 xl:col-span-4":
                currentUser?.role === "admin",
              "col-span-12": currentUser?.role === "reception",
              hidden: currentUser?.role === "content-manager",
            })}
          >
            <Card
              loading={loading}
              className="overflow-scroll h-full"
              bordered={false}
              title="Запросы на обратный звонок"
              extra={<Link to="/callbacks">Открыть все</Link>}
            >
              <List
                dataSource={data?.callbacks}
                size="small"
                renderItem={(item) => (
                  <List.Item className="m-0 px-0 py-2">
                    <div>
                      <Badge
                        status={
                          item?.status === "new" ? "processing" : "default"
                        }
                        text={item?.name}
                      />
                      <a
                        href={`tel:${item?.phone
                          ?.replace(/\s/g, "")
                          .replace(/-/g, "")}`}
                        className="block text-sm font-medium text-black m-0"
                      >
                        {item?.phone}
                      </a>
                    </div>
                    <Link to={`/callbacks`}>
                      <Button size="small">Открыть</Button>
                    </Link>
                  </List.Item>
                )}
              />
            </Card>
          </div>
          <div
            className={classNames({
              "col-span-12 md:col-span-6 xl:col-span-4":
                currentUser?.role === "admin",
              hidden: currentUser?.role !== "admin",
            })}
          >
            <Card
              loading={loading}
              className="overflow-scroll h-full"
              bordered={false}
              title="Номера"
              extra={<Link to="/rooms">Открыть все</Link>}
            >
              <List
                dataSource={data?.rooms}
                size="small"
                renderItem={(item) => (
                  <List.Item className="m-0 px-0 py-2">
                    <div>
                      <Badge
                        status={item?.active ? "success" : "default"}
                        text={item?.active ? "На сайте" : "Черновик"}
                      />
                      <Link
                        to={`/rooms/edit/${item?.id}`}
                        className="block text-sm font-medium text-black m-0"
                      >
                        {item?.name}
                      </Link>
                    </div>
                    <Link to={`/rooms/edit/${item?.id}`}>
                      <Button size="small">Открыть</Button>
                    </Link>
                  </List.Item>
                )}
              />
            </Card>
          </div>
          <div
            className={classNames({
              "col-span-12 md:col-span-6 xl:col-span-4":
                currentUser?.role === "admin",
              "col-span-12": currentUser?.role === "content-manager",
              hidden: currentUser?.role === "reception",
            })}
          >
            <Card
              loading={loading}
              className="overflow-scroll h-full"
              bordered={false}
              title="Страницы"
              extra={<Link to="/pages">Открыть все</Link>}
            >
              <List
                dataSource={data?.pages}
                size="small"
                renderItem={(item) => (
                  <List.Item className="m-0 px-0 py-2">
                    <div>
                      <Badge
                        status={item?.active ? "success" : "default"}
                        text={item?.active ? "На сайте" : "Черновик"}
                      />
                      <Link
                        to={`/pages/edit/${item?.id}`}
                        className="block text-sm font-medium text-black m-0"
                      >
                        {item?.name}
                      </Link>
                    </div>
                    <Link to={`/pages/edit/${item?.id}`}>
                      <Button size="small">Открыть</Button>
                    </Link>
                  </List.Item>
                )}
              />
            </Card>
          </div>
          <div className="col-span-12">
            <div className="grid grid-cols-12 gap-4">
              <div
                hidden={currentUser?.role === "content-manager"}
                className="col-span-6 md:col-span-3 lg:col-span-2"
              >
                <Link
                  to="https://pms.frontdesk24.ru/v1/booklist.html"
                  target="_blank"
                  className="aspect-square w-full rounded-lg bg-primary bg-opacity-10 flex flex-col gap-2 justify-center items-center hover:bg-opacity-5"
                >
                  <ShopOutlined className="text-primary text-4xl" />
                  <span className="block w-full text-center text-sm">
                    Бронирования
                  </span>
                </Link>
              </div>
              <div
                hidden={currentUser?.role !== "admin"}
                className="col-span-6 md:col-span-3 lg:col-span-2"
              >
                <Link
                  to="/settings/global"
                  className="aspect-square w-full rounded-lg bg-primary bg-opacity-10 flex flex-col gap-2 justify-center items-center hover:bg-opacity-5"
                >
                  <SettingOutlined className="text-primary text-4xl" />
                  <span className="block w-full text-center text-sm">
                    Настройки сайта
                  </span>
                </Link>
              </div>
              <div
                hidden={currentUser?.role === "reception"}
                className="col-span-6 md:col-span-3 lg:col-span-2"
              >
                <Link
                  to="/settings/map"
                  className="aspect-square w-full rounded-lg bg-primary bg-opacity-10 flex flex-col gap-2 justify-center items-center hover:bg-opacity-5"
                >
                  <CompassOutlined className="text-primary text-4xl" />
                  <span className="block w-full text-center text-sm">
                    Настройки карт
                  </span>
                </Link>
              </div>
              <div
                hidden={currentUser?.role === "reception"}
                className="col-span-6 md:col-span-3 lg:col-span-2"
              >
                <Link
                  to="/settings/map"
                  className="aspect-square w-full rounded-lg bg-primary bg-opacity-10 flex flex-col gap-2 justify-center items-center hover:bg-opacity-5"
                >
                  <UnorderedListOutlined className="text-primary text-4xl" />
                  <span className="block w-full text-center text-sm">
                    Настройки навигации
                  </span>
                </Link>
              </div>
              <div className="col-span-6 md:col-span-3 lg:col-span-2">
                <Link
                  to="/users"
                  className="aspect-square w-full rounded-lg bg-primary bg-opacity-10 flex flex-col gap-2 justify-center items-center hover:bg-opacity-5"
                >
                  <TeamOutlined className="text-primary text-4xl" />
                  <span className="block w-full text-center text-sm">
                    Администраторы
                  </span>
                </Link>
              </div>
              <div
                hidden={currentUser?.role === "reception"}
                className="col-span-6 md:col-span-3 lg:col-span-2"
              >
                <Link
                  to="/pages/new"
                  className="aspect-square w-full rounded-lg bg-primary bg-opacity-10 flex flex-col gap-2 justify-center items-center hover:bg-opacity-5"
                >
                  <PlusOutlined className="text-primary text-4xl" />
                  <span className="block w-full text-center text-sm">
                    Новая страница
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}
