import style from "./style.module.scss";
import classNames from "classnames";

export default function Minioptions({ data, className, ...props }) {
  return (
    <div
      {...props}
      className={classNames(
        style.container,
        "container content-max",
        className,
        "my-0",
        {
          "max-w-3xl": props?.options?.width === "small",
          [props?.options?.class]: props?.options?.class,
        }
      )}
    >
      <div className="grid grid-cols-12 gap-4">
        {data?.minioptions?.map((item, i) =>
          item?.href ? (
            <a
              href={item?.href}
              rel={item?.href_rel || item?.href_target_blank ? "noopener" : ""}
              target={item?.href_target_blank ? "_blank" : "_self"}
              key={i}
              title={item?.label || ""}
              className={classNames(
                "col-span-12 sm:col-span-6 flex justify-start items-center gap-4",
                {
                  "md:col-span-3":
                    !props?.options?.size || props?.options?.size === "base",
                  "md:col-span-4": props?.options?.size === "lg",
                  "md:col-span-6": props?.options?.size === "xl",
                }
              )}
            >
              <div className={style.iconContainer}>
                <img
                  style={{ userDrag: "none" }}
                  draggable="false"
                  src={`/img/icons/${item?.icon || "check"}.svg`}
                />
              </div>
              <div>
                <span className="block leading-tight text-black text-xl md:text-2xl">
                  {item?.label}
                </span>
                {item?.subtitle && (
                  <span className="block leading-tight text-base">
                    {item?.subtitle}
                  </span>
                )}
              </div>
            </a>
          ) : (
            <div
              key={i}
              className={classNames(
                "col-span-12 sm:col-span-6 flex justify-start items-center gap-4",
                {
                  "md:col-span-3":
                    !props?.options?.size || props?.options?.size === "base",
                  "md:col-span-4": props?.options?.size === "lg",
                  "md:col-span-6": props?.options?.size === "xl",
                }
              )}
            >
              <div className={style.iconContainer}>
                <img
                  style={{ userDrag: "none" }}
                  draggable="false"
                  src={`/img/icons/${item?.icon || "check"}.svg`}
                />
              </div>
              <div>
                <span className="block leading-tight text-black text-lg">
                  {item?.label}
                </span>
                {item?.subtitle && (
                  <span className="block leading-tight text-base">
                    {item?.subtitle}
                  </span>
                )}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}
