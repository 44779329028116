import { Form, Input } from "antd";

export default function InputField(props) {
  return (
    <Form.Item
      {...props}
      name="booking_widget_script"
      className="w-full"
      label="Скрипт виджета"
    >
      <Input.TextArea
        controls={false}
        rows={4}
        className="w-full"
        placeholder="Вставьте HTML код виджета"
      />
    </Form.Item>
  );
}
