import { Modal, Tag } from "antd";
import { useEffect } from "react";
import dayjs from "dayjs";
import ru from "dayjs/locale/ru";
import * as API from "@api";

dayjs.locale(ru);

export default function View({ open, onCancel, data, ...props }) {
  useEffect(() => {
    if (open && data && data?.status === "new") {
      API.Callbacks.update({
        id: data?.id,
        status: "read",
      });
    }
  }, [open]);

  if (!data) {
    return;
  }

  return (
    <Modal
      title="Информация о запросе"
      open={open}
      onCancel={onCancel}
      destroyOnClose
      centered
      okButtonProps={{ hidden: true }}
      cancelText="Готово"
      {...props}
    >
      <div className="w-full grid grid-cols-12 gap-4 mb-10">
        <div className="col-span-12 md:col-span-6">
          <span className="block text-sm text-neutral-500">Дата запроса</span>
          <span className="block text-base text-black">
            {dayjs(data?.created_at).format("HH:mm DD MMM YYYY")}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6">
          <span className="block text-sm text-neutral-500">Статус</span>
          <span className="block text-base text-black">
            {data?.status === "new" && (
              <Tag className="m-0" color="red">
                Новый
              </Tag>
            )}
            {data?.status === "read" && (
              <Tag className="m-0" color="blue">
                Просмотрен
              </Tag>
            )}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6">
          <span className="block text-sm text-neutral-500">Имя</span>
          <span className="block text-base text-black">{data?.name}</span>
        </div>
        <div className="col-span-12 md:col-span-6">
          <span className="block text-sm text-neutral-500">Номер телефона</span>
          <span className="block text-base text-black">{data?.phone}</span>
        </div>
      </div>
    </Modal>
  );
}
