import { Alert } from "antd";

export default function Widget({ input, onChange, key, data }) {
  return (
    <div className="col-span-12">
      <Alert
        type="success"
        showIcon
        className="w-full"
        description="Нажмите «Добавить», чтобы вставить виджет бронирования. Настроить API виджета бронирования можно на странице «Настройки сайта»"
      />
    </div>
  );
}
