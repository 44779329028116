import { Rooms, OfflineWidget } from "@modules";
import { Breadcrumb } from "@components";
import { useParams } from "react-router-dom";

export default function EditPage() {
  const { id } = useParams();

  return (
    <div className="container max-content">
      <Breadcrumb
        data={[
          { title: "Номера", href: "/rooms" },
          { title: "Редактировать номер", href: `/rooms/edit/${id}` },
        ]}
      />
      <h1 className="mb-8 text-3xl">Редактировать номер</h1>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Rooms.Edit id={id} />
        </div>
      </div>
      <OfflineWidget />
    </div>
  );
}
