module.exports = {
  env: process.env.NODE_ENV || process.env.REACT_APP_ENV || "production",
  port: process.env.PORT || process.env.REACT_APP_PORT || "3001",
  api: process.env.REACT_APP_API_ENDPOINT,
  frontend: process.env.REACT_APP_FRONTEND_ENDPOINT,
  basepath: process.env.REACT_APP_BASEPATH,
  reCaptchaKey: process.env.REACT_APP_RECAPTCHA_SITEKEY,
  revalidateSecret: process.env.REACT_APP_REVALIDATE_SECRET,
  hashidsSalt: process.env.REACT_APP_HASHIDS_SALT,
  nextRevalidateKey: process.env.REACT_APP_NEXT_REVALIDATE_KEY,
};
