import { Form, Input } from "antd";

export default function InputField(props) {
  return (
    <Form.Item
      help="Номер телефона без пробелов и знака +. Напр. 77010000000"
      name="wp_widget_phone"
      className="w-full"
      label="Номер телефона"
      rules={[
        {
          required: true,
          message: "Это поле обязательно",
        },
      ]}
    >
      <Input
        controls={false}
        className="w-full"
        placeholder="Введите номер телефона"
      />
    </Form.Item>
  );
}
